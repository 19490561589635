export function Validate3(formData, errors){

    const {Address, Email, Name, Phone, typemessages } = formData;

    if(typemessages && !Name) errors['Name'].addError("Зазначте ваше прізвище , ім’я та по батькові");

    if(typemessages && !Email) errors['Email'].addError("Залиште адресу електронної пошти або адресу для листування");

    if(!Phone || (Phone.length == 0)  ) return errors;

    const digit_v = [0,1,2,3,4,5,6,7,8,9];

    const minLength = 12;

    const nmF = Phone.replace(/\s+/g, "").split('').filter(el=>digit_v.includes(+el)).join('');
    
    if(nmF.length < minLength) errors['Phone'].addError("Номер телефону має складати 12 цифр");

    return errors;

}