const statInfo = async (func, params)=> {

    const url = `api/statistics?central=Всі&territorial=Всі&region=Всі&channel=Всі&typeAnkets=Всі&period=Всі&date_from=&date_to=`;
    const f = await fetch(url);

    if(f.ok) {
        const data =  await f.json();
        
        func(data.data);
    } else {
        alert('Вибачте, виникла помилка при отриманні статистики');
        func({});
    }

};

export { statInfo };