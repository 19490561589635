import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import gerald from './gerald.svg';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root:{
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "27px",
        color: "#FFFFFF"
    },
    box:{
        paddingLeft: "50px",
        paddingRight: "50px",
//        background: "radial-gradient(50% 88.89% at 50% 44.72%, #4A96C4 0%, #3973A1 100%)"
background: "linear-gradient(90deg,#4376B3 0%,#477DBD 34.9%,#234260 100%);",

},
    centered:{
        marginLeft: "20px"
    }
});

export function Footer(){

    const classes = useStyles();

    return(
        
        <Box className={classes.box}>
            <Grid container spaicing={2} className={classes.root}>
                
                    <Grid item xs={2}>Мапа сайту</Grid>
                    <Grid item xs={9}>^</Grid>

                    <Grid item xs={12} align="center"><p><img src={gerald}/></p></Grid>

                    <Grid item xs={4} alignItems="left"><p>Адреса</p><p>Україна,  01103 м. Київ,  бульвар Дружби Народів, 28</p></Grid>
                    <Grid item xs={4} align="center" alignItems="center"><p>Єдиний портал повідомлень викривачів</p></Grid>
                    <Grid item xs={2} alignItems="left"><p>Електронна адреса</p><p>info@nazk.gov.ua</p></Grid>
                    <Grid item xs={2} align="center"><p>НАЗК в соцмережах:</p><p> - - - - </p></Grid>

                    <Grid item xs={12} align="center">
                        <p>Весь контент доступний за ліцензією</p>
                        <p>Creative Commons Attribution 4.0 International license, якщо не зазначено інше </p>
                    </Grid>
                
            </Grid>
        </Box>
        
    )
}