import React, { useState, useEffect } from 'react';

import { Header } from '../../components/header';
import { Footer1 } from '../../components/footer1';
import { MainBreadcrumbs } from '../../components/breadcrumb';

import { statInfo } from './actions';

import { makeStyles } from '@material-ui/core/styles';

import { AnaliticBlock1 } from './components/block1';
import { AnaliticBlock2 } from './components/block2';
import { AnaliticBlock3 } from './components/block3';
import { AnaliticBlockMap } from './components/map';

import { SelectMap } from './components/map/regionSelect';

//import '../main.css';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  body:{
      maxWidth: '1280px',
      minHeight: '600px',
      margin: 'auto',
      padding: '40px 85px 0px 85px'
  },
  form:{
      //padding: '50px'
  }
}));

const path_br = [
    { title: "Головна", link: "/" }
];

const cur_br = { title: "Аналітика", link: "" }


export function AnaliticaPage(props) {

    const getCentralPage = ()=>{
        const { location } = props;

        const { state } = location;

        return state && state.central || 'Всі'; 
    }

    const getOrganizationsPage = ()=>{

        const { location } = props;

        const { state } = location;

        if(!state || !state.organizations) return null;

        const central = state.organizations.filter(row=>!row.isTerritorialAuthorities);

        const territorial = {};

        state.organizations.filter(row=>row.isTerritorialAuthorities).forEach(row=>{
          
          if(!territorial[row.edrpouCentralAuthorities]) territorial[row.edrpouCentralAuthorities] = [];

          territorial[row.edrpouCentralAuthorities].push(row);

        });


        return ({central, territorial});

    }


    
    const classes = useStyles();

    const organ_blank = { central: [], territorial: {}};

    const [statistics, setStatistics] = useState(null);
    const [organizations, setOrganizations] = useState(getOrganizationsPage());

    const [central, setCentral] = useState(getCentralPage());
    const [person, setPerson] = useState('Всі');
    const [source, setSource] = useState('Всі');
    const [territorial, setTerritorial] = useState('Всі');
    const [region, setRegion] = useState('Всі');

    const [typeAnkets, setTypeAnkets] = useState('Всі');

    const [channel, setChannel] = useState('Всі');

    const [period, setPeriod] = useState('Всі');
    const [date_from, setDateFrom] = useState('');
    const [date_to, setDateTo] = useState('');

    const [status_message, setStatusMsg] = useState('Всі');
    const [status_person, setStatusPrs] = useState('Всі')






    const getOrganizations = async () => {
        const url = '/api/organization';
        const f = await fetch(url);
        const data =  await f.json();

        const central = data.data.filter(row=>!row.isTerritorialAuthorities);

        const territorial = {};

        data.data.filter(row=>row.isTerritorialAuthorities).forEach(row=>{
          
          if(!territorial[row.edrpouCentralAuthorities]) territorial[row.edrpouCentralAuthorities] = [];

          territorial[row.edrpouCentralAuthorities].push(row);

        });

        setOrganizations({central, territorial});
    }



    const updateStat = ()=> {
        statInfo(setStatistics, {central, territorial, region, channel, typeAnkets,period, date_from, date_to, person, source, status_person, status_message});
    }

    useEffect(()=>{

        if(!organizations || !organizations.central || organizations.central.length < 1 ){
            getOrganizations();
        }
    }, [organizations]);

    useEffect(()=>{
        //if(statistics !== null) return;

        updateStat();

    }, [central, territorial, region, typeAnkets,channel, period, date_from, date_to, person, source, status_message, status_person])

    if(statistics == null) return null;

    return(
        <div >
            <Header short={true} />
            <div className={classes.body}>
                <MainBreadcrumbs path={path_br} current={cur_br}/>
                <LabelBlock/>
                <AnaliticBlock2  
                    organizations={organizations || organ_blank} 
                    statistics={statistics}
                    region={region}
                    setCentral={setCentral}
                    central={central}
                    setPerson={setPerson}
                    setSource={setSource}
                    setTerritorial={setTerritorial}
                    setRegion={setRegion}
                    setTypeAnkets={setTypeAnkets}
                    channel={channel}
                    setChannel={setChannel}
                    setPeriod={setPeriod}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    setStatusMsg={setStatusMsg}
                    setStatusPrs={setStatusPrs}
                    date_from={date_from}
                    date_to={date_to}
                    typeAnkets={typeAnkets}
                    source={source}
                    status_message={status_message}
                    status_person={status_person}
                />
                {false && <AnaliticBlock3  organizations={organizations && organizations || organ_blank}/>}
                { false && <AnaliticBlock1 data={ statistics }/>}

                {false && <SelectMap />}
                {false && <AnaliticBlockMap />}
            </div>
            <Footer1 />
        </div>
    )
}

function LabelBlock(){
    return(
        <div className="anketa-header-titul">
            <span>Аналітика</span>
        </div>
    )
}