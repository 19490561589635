import React, { useRef } from 'react';
import Form from '@rjsf/material-ui';

import { green, purple, yellow } from '@material-ui/core/colors';


import { Typography, Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";

import { FileDropzone } from '../widgets/filedropzone';
import { EditorWidget } from '../widgets/editor';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { AutoCompleteMultiSelect } from '../widgets/atocomplmselect';

import { MultipleSelect } from '../widgets/multiselects';
import { AutoCompliteComboBox } from '../widgets/autocomplite';
import { AutoCompliteComboBox1 } from '../widgets/autocomplite1';

import InputMask from 'react-input-mask';
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import { makeStyles, withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';

import { AddressAutocompleate } from './addressCompl';

const inStyle = {
    Title: {
      fontSize: '16px',
      fontFamily: 'Ubuntu, sans-serif',
      fontWeight: '400',
      lineHeight: 1.334,
      letterSpacing: '0em',
      //borderBottom: '1px solid lightgray',
      marginTop: '8px',
      marginBottom: '8px'
    },
      TitleMultiselect: {
      fontSize: '16px',
      fontFamily: 'Ubuntu, sans-serif',
      fontWeight: '400',
      lineHeight: 1.334,
      letterSpacing: '0em',
  //    borderBottom: '1px solid lightgray',
      marginTop: '0px',
      marginBottom: '0px'
    },
    Desc:{
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px'
    },
    Block:{
      paddingBottom: '15px'
    }
  }

  const styleLabelInfo = {
    backgroundColor: "cornsilk",
    padding: '30px 20px 30px',
    fontSize: '0.9rem',
    lineHeight: '1.5'
  }

const styleTitle = {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1.3rem',
    color: 'black',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    paddingBottom: '10px'
}

const styleSubmitHidden = {
  visibility: 'hidden',
  width: '0px'
};

const TitleInfo = (props)=><div>
    <div style={styleTitle}>{props.schema.title}</div>
    
  </div>

const LabelInfo = (props) => <div>
  <div style={styleLabelInfo} dangerouslySetInnerHTML={{ __html: props.schema.title}}/>
</div>

const AutoComplObj = (props) => <AutoCompliteComboBox options={props.schema.enumt} optionsName={props.schema.enumName} label={props.schema.title} value={props.value} onChange={(event, value) => props.onChange(value && JSON.stringify(value) || null )} />

const AutoComplObj_1 = (props) => <AutoCompliteComboBox options={props.schema.enum} optionsName={props.schema.enumName} label={props.schema.title} value={props.value} onChange={(event, value) => props.onChange(value && JSON.stringify(value) || null)} />


const AutoCompl = (props) => <AutoCompliteComboBox options={props.schema.enum} label={props.schema.title} value={props.value} onChange={(event, value) => props.onChange(value)} />


const MaskTel = (props)=>
            <InputMask 
                mask="+380(99) 999 99 99" 
                value={props.value}
                onChange={(event) => props.onChange(event.target.value)}
                disabled={false}
                children={<TextField label={props.schema.title + (props.required ? '*' : '') }/>}/>
            

const MaskEmail = (props)=> <MuiThemeProvider><TextField placeholder="email@example.com" type="email" oninvalid="this" name="email" label={props.schema.title + (props.required ? '*' : '')} value={props.value} onChange={(event) => { event.stopPropagation(); event.preventDefault(); props.onChange(event.target.value) }} /></MuiThemeProvider>

const AddressInput = (props) => <AddressAutocompleate  setValue={(v) => props.onChange(v)} value={props.value}/>



const FileReaderWidget = (props)=>(
<div style={inStyle.Block}>
    <div style={inStyle.Title}>{props.schema.title + (props.required ? '*' : '') }</div>
    <div style={inStyle.Desc} >{props.schema.description1}</div>
    <FileDropzone 
        onChange={(data) => {
            props.onChange(data)
        }}
/>
</div>
)

const MultiSelect = (props) =>(
  <div>
    <div style={inStyle.TitleMultiselect}>{props.schema.title+ (props.required ? '*' : '')}</div>
    <div style={inStyle.Desc} >{props.schema.description1}</div>
    <MultipleSelect
        value={props.value && JSON.parse(props.value)}
        items={props.schema.enum1}
        itemNames={props.schema.enum1}
        onChange={(data) => {
            props.onChange(JSON.stringify(data) );
        }}
    />
</div>
)


const TextEditorWidget = (props)=>
<div style={inStyle.Block}>
    <div style={inStyle.Title}>{props.schema.title + (props.required ? '*' : '') }</div>
    <div style={inStyle.Desc} >{props.schema.description1}</div>
<EditorWidget
    required = {props.required}
    onChange={(data, e)=>{
        props.onChange(data);
    }}
    value={props.value || ""}
/>
</div>

//const HorizontalBoolRadio = ()


const HorizontalBoolRadio = (props)=>(
  <div>
    <div>{props.label}</div>
  <RadioGroup row aria-label="position" name="position">
    <FormControlLabel value="end" control={<Radio color="primary" checked={props.value} onChange={(data)=>props.onChange(true)} />} label={props.options.enumOptions[0].label} />
    <FormControlLabel value="end" control={<Radio color="primary" checked={!props.value} onChange={(data)=>props.onChange(false)} />} label={props.options.enumOptions[1].label} />
  </RadioGroup>
  </div>
)

const SwitchRadio = (props)=>(
  <div>
    <div>{props.label}</div>
    <Switch row aria-label="position" name="position" color="primary" checked={props.value} onChange={(e)=>props.onChange(e.target.checked)} />
  </div>
)

const InputUkrText = (props) => {
  return (
    
    <div class="MuiFormControl-root MuiTextField-root">
      <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated Mui-required Mui-required" data-shrink="false" for="root_Address" id="root_Address-label">{props.label}</label>
      <div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
        <input type="text"
  //      className="custom"
        value={props.value}
        required={props.required}
        placeholder={props.placeholder}
        onInvalid={e => e.target.setCustomValidity('Enter User Name Here')}      
        onChange={(event) => props.onChange(event.target.value)} />
      </div>
    </div>
  );
}

const customFormats = {
    'phone-us': /\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/
};

const transformErrors = errors => {

  const transformVal = {
    'required': 'Обов\'язкове поле',
    'pattern': 'Невірне заповнення поля',
    'minLength': 'Невірне заповнення поля',
    'type': ' ',
    'enum': 'Оберіть принаймні одне значення'
  }
    return errors.map(error => {

      // use error messages from JSON schema if any
      if (error.name && transformVal[error.name]) {
        return {
          ...error,
          message: transformVal[error.name]
        };
      }
      return error;
    });
  };

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    //width: '250px',
    textTransform: 'initial',
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: '#BEE6FD',
    },
  },
}))(Button);

export function FormAnketa(props){

    const { key_step, formData, schema, uiSchema, onSubmit, validate, isFinish, setTextAddInfo, classes, handleReset, label, isBegin, onChangeForm } = props;

    let directStep = 'none';


    const sendOnMessage = (data)=>onSubmit(Object.assign(data, {directStep}));

    const handleBack = ()=>{
      directStep = 'back';
      submitButton.current?.click();
    };

    const handleNext = ()=>{
      directStep = 'forward';
      submitButton.current?.click();
    };


    const submitButton = useRef(null);

    const AutoComplMultiSelect = (props) =>{  
        const { addInfo, enum2 } = props.schema ;

        const pref_st = '<p><h3>Стаття {X}:</h3> </p>';

        const setInfo = data => {
          if (!data) return setTextAddInfo("");
          const indx = addInfo.findIndex(el=>el.f == data);
          if(indx > -1 && (addInfo[indx].t !== "")) return setTextAddInfo(pref_st.replace('{X}', enum2[indx]) + addInfo[indx].t);
        }
        
        const findAddInfo = (data)=>{          

          if (!data || !Array.isArray(data) || data.length < 1) {
              setTextAddInfo("");
              return;
          }
  
          const indx = addInfo.findIndex(el=>el.f == data[data.length - 1]);
    
          if(indx > -1 && (addInfo[indx].t !== "")) setTextAddInfo(pref_st.replace('{X}', enum2[indx]) + addInfo[indx].t);

          if(indx == -1 || (addInfo[indx].t == "")) setTextAddInfo("");
        }
        
        return (
        <div>
          <div style={inStyle.TitleMultiselect}>{props.schema.title+ (props.required ? '*' : '')}</div>
          <div style={inStyle.Desc} >{props.schema.description1}</div>
          <AutoCompleteMultiSelect
              value={props.value && JSON.parse(props.value)}
              items={props.schema.enum1}
              //itemNames={props.schema.enum1}
              onChange={(data) => {
                  props.onChange(JSON.stringify(data));
                  if(addInfo) setInfo("", addInfo);
              }}
              setInfo={(data) => {
                  if(addInfo) setInfo(data, addInfo);
              }}
          />
      </div>
      )}

    const widgets = {
        FileReader: FileReaderWidget,
        TextEditor: TextEditorWidget,
        HRB: HorizontalBoolRadio,
        SWR: SwitchRadio,
        MMS: MultiSelect,
        MPHONE: MaskTel,
        TTI: TitleInfo,
        MEMAIL: MaskEmail,
        ATCMPL: AutoCompl,
        ATMSL: AutoComplMultiSelect,
        LBI: LabelInfo,
        ATCMPLC: AutoComplObj,
        ATCMPLC1: AutoComplObj_1,
        InputUkrText:InputUkrText,
        Address: AddressInput
    };
    

    return (
        <Form
            key={key_step}
            formData={formData}
            schema={schema}
            uiSchema={uiSchema}
            //fields={fields}
            widgets={widgets}
            onSubmit={sendOnMessage}
            onChange={({ formData}) => onChangeForm(formData)}
            noHtml5Validate={false}
            customFormats={customFormats}
            showErrorList={false}
            transformErrors={transformErrors}
            validate={validate}
            className="anketa-form"
        >
        {false && isFinish ? (
        <div>
            <Typography className={classes.instructions}>
            Всі кроки пройдені
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
            Наново
            </Button>
        </div>
        ) : (
        <div>
            {label}
            <Typography className={classes.instructions}></Typography>
            <div>
            <input
              ref={submitButton}
              style={styleSubmitHidden}
              type="submit"
              value="Submit"
            />
            <Button 
                    type="button" 
                    size="medium" 
                    disabled={isBegin} 
                    onClick={handleBack} 
                    className={classes.button}>
                Назад
            </Button>

            <ColorButton
                className={classes.next_button}
                variant="contained"
                color="primary"
                type="button"
                onClick={handleNext}
                size="large"
                //className={classes.button}
            >
                {isFinish? 'Подати повідомлення' : 'Далі'}
            </ColorButton>
            </div>
        </div>
            )}
        </Form>
    )
}