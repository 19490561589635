export function Validate1(formData, errors){

    const { Organization } = formData;

    console.log('errors', errors);

    if(!Organization || (Organization == null) ) errors['Organization'].addError("Обов'язкове поле. Зазначте організацію");

    return errors;

}