import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    display: 'grid',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export function MultipleSelect(props) {

  const { items, itemNames, onChange, value } = props;


  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  React.useEffect(()=>{
    if(!value) return;
    setPersonName(value);
  },[value]);

  const handleChange = (event) => {

    const { value } = event.target;

    //setPersonName(value);

    onChange(value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  return (
      <div className={classes.formControl}>
        <Select
      
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={<Typography style={{whiteSpace: 'normal'}}>{itemNames[items.findIndex(el=>el == value)]}</Typography> } 
                className={classes.chip}
                style={{height:"100%"}}
                 />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {items.map((name, indx) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={itemNames[indx]} />
            </MenuItem>
          ))}
        </Select>
        </div>
  );
}
