import React from 'react';
import Button from '@material-ui/core/Button';

import  Link  from '@material-ui/core/Link';

import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { green, purple, yellow } from '@material-ui/core/colors';

import MainLogo from './main_logo.svg';

import S1svg from './img/step1.svg';
import S2svg from './img/step2.svg';
import S3svg from './img/step3.svg';
import S4svg from './img/step4.svg';
import S5svg from './img/step5.svg';

import HT1 from './img/h_top-1.svg';
import HT2 from './img/h_top-2.svg';
import HT3 from './img/h_top-3.svg';
import HT4 from './img/h_top-4.svg';
import HT5 from './img/h_top-5.svg';

import { ReactComponent as OrganizConnect } from './img/organizations.svg';


import './central.css';
import './style.min.css';
import { CallMissedSharp } from '@material-ui/icons';



const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('#FCDA4B'),
      backgroundColor: '#FCDA4B',
      padding: '5px 10px 5px 10px',
      borderRadius: '5px',
      textTransform: 'none',
      fontWeight: '500',
      fontSize: '20px',
      textDecoration: 'none',
      border: '0px solid #FCDA4B',
      '&:hover': {
        backgroundColor: '#BEE6FD',//yellow[700],
        //color: '#FCDA4B',
        border: '0px solid #FCDA4B',
        textDecoration: 'none'
      }
    }
  }))(Button);




  const useStyles = makeStyles((theme)=>({
      logo: {
          textAlign: "end"
      },
      main_info: {
              display: 'grid',
              paddingTop: '35px',
              paddingBottom: '35px',
              
              'grid-template-columns': '45% 55%',
              '&::before':{
                    content: '""',
                    width: '100%',
                    height: '200px',
                //    top: 'calc(50% - (200px / 2))',
                    position: 'absolute',
                    background: `url(${MainLogo}) right/auto 100% no-repeat`,
                    left: '0px'
              }
    },
    central_m: {
        maxWidth: '1280px',
        position: 'relative',
        paddingBottom: '26px',
        '& ::before':{
            content: '"1"',
            position: 'absolute',
            width: '100%',
            height: '200px',
            top: 'calc(50% - (200px / 2))',
            backgroundImage: `url('${S5svg}'), url('${S4svg}'), url('${S3svg}'), url('${S2svg}'),url('${S1svg}')`,
            //backgroundRepeat: 'no-repeat',
            backgroundPosition: 'calc(100% - 0px) center, calc(100% - 125px) center, calc(100% - 270px) center, calc(100% - 415px) center, calc(100% - 530px) center',
            //animationName: `$opacity`,
            //animationDuration: '3s, 4s, 5s, 6s, 7s'
        },
        [theme.breakpoints.up('md')]:{
            paddingBottom: '0px'
        }
    },
    organizations: {
        'text-decoration': 'none',
        padding: '20px 50px',
        color: 'rgb(252, 218, 75)',
        'vertical-align': 'middle',
        fill: '#FCDA4B',
        '&:hover':{
          '& span':{
            'text-decoration': 'none',
            color: '#BEE6FD'
          },
            fill: '#BEE6FD'
        },
        '& svg':{
          'vertical-align': 'inherit',
          fill: 'inherit',
        }
    }
  }));
  

export function CentralHeader() {

    const classes = useStyles();

    return(
        <div className={classes.central_m}>
            <div className="central-action">
                <div className="main_text_central">Єдиний портал повідомлень викривачів</div>
                <div className="central_info">Ви можете подати повідомлення про факти корупційних або пов’язаних з корупцією правопорушень, інших порушень <a href="https://zakon.rada.gov.ua/laws/show/1700-18#Text">Закону України «Про запобігання корупції»</a></div>
                <div>
                <div className="central_button">
                <Link href='#/anketa' style={ {'text-decoration': 'none' }}>
                <ColorButton variant="contained" color="primary" >
                    Подати повідомлення
                </ColorButton>
                </Link>
                
                </div>
                </div>
            </div>
            <div className={classes.logo}>
            </div>
            <div className="info_banner-imgs">
				<div className="info_banner-imgs--item ibi-1">
					<img src={HT1} alt=""/>
				</div>
				<div className="info_banner-imgs--item ibi-2">
					<img src={HT2} alt=""/>
				</div>
				<div className="info_banner-imgs--item ibi-3">
					<img src={HT3} alt=""/>
				</div>
				<div className="info_banner-imgs--item ibi-4">
					<img src={HT4} alt=""/>
				</div>
				<div className="info_banner-imgs--item ibi-5">
					<img src={HT5} alt=""/>
				</div>
			</div>
        </div>
    )
}