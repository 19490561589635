import React, { useState } from 'react';

import './style.css';
import banner from './img/banner.svg';

import { Banner } from './banner/index';

export function FAQ(){

    const [isOpen, setOpen] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
		false,
		false,
		false
    ]);


    const onClick = (num) => {
        const newState = [...isOpen];
        newState[num] = !isOpen[num];
        setOpen(newState);
	};
	

	const content = [
		{ "title": "Що таке портал", "text": ["Портал – це захищена система, за допомогою якої можна швидко та зручно подати повідомлення про корупцію, контролювати стан та результати розгляду такого повідомлення, отримати інформацію про статус викривача.", "Портал гарантує заявникам дотримання умов анонімності та конфіденційності, надійний захист персональних даних."]},
		{ "title": "Куди надходить повідомлення, подане через портал", "text":["Повідомлення про корупцію, подане через портал, надходить по внутрішньому каналу повідомлення до керівника або уповноваженого підрозділу (особи) установи, в якій працює (перебуває на посаді) особа, яка вчинила правопорушення. Таку установу заявник обирає на Кроці 1 при поданні повідомлення про корупцію.", "Якщо портал встановить, що повідомлення містить інформацію про корупційне або пов’язане з корупцією правопорушення, заявнику буде запропоновано подати повідомлення по регулярному каналу – спеціально уповноваженому суб’єкту у сфері запобігання корупції або Державному бюро розслідувань."]}
	]

    
    return(
        <section className="sect quest">
			<div className="container">
			<div className="quest-cont">
				<h2 className="sh-title">Часті питання</h2>
				<div className="accordion">
{ content.map((item, index)=> {

	const start = 7;

	return			<div className="accordion-item">
						<div className="accordion-btn" onClick={()=>onClick(start + index)}>
							<span className="accordion--title">{item.title}</span>
							<i className={"ico_acord_slide" + (isOpen[start + index] ? " active": "") } ></i>
						</div>

						<div className="accordion-slide" aria-expanded={isOpen[start + index]}>
						<div className="accordion-slide-main">
							<span className="accordion-slide--list">
							
							{item.text.map(el=>
							<p>{el}</p>
							)}
							
							</span>
							</div>							
						</div>
				</div>
}

)}
					<div className="accordion-item">
						<div className="accordion-btn" onClick={()=>onClick(0)}>
							<span className="accordion--title">Зміст повідомлення</span>
							<i className={"ico_acord_slide" + (isOpen[0] ? " active": "") } ></i>
						</div>

						<div className="accordion-slide" aria-expanded={isOpen[0]}>
						<div className="accordion-slide-main">
							<span className="accordion-slide--list">Повідомлення про корупцію має містити фактичні дані про корупційне або пов’язане з корупцією правопорушення, інше порушення Закону України «Про запобігання корупції», які можуть бути перевірені.</span>
							<p> Фактичні дані – це відомості про обставини правопорушення, місце і час його вчинення, особу, яка вчинила правопорушення, тощо.</p>
							</div>							
						</div>
					</div>

					<div className="accordion-item">
						<div className="accordion-btn" onClick={()=>onClick(1)}>
							<span className="accordion--title">Як повідомити про корупцію на порталі?</span>
							<i  className={isOpen[1] ? "ico_acord_slide active" : "ico_acord_slide"} ></i>
						</div>

						<div className="accordion-slide" aria-expanded={isOpen[1]} >
						<div className="accordion-slide-main">
							<span className="accordion-slide--list">На порталі натисніть кнопку «Подати повідомлення про корупцію», введіть таку інформацію та оберіть такі дії:</span>
							<ul className="accordion-slide--list">
								<li>оберіть організацію, про корупцію в якій ви хочете повідомити;</li>
								<li>вкажіть, хто вчинив правопорушення;</li>
								<li>напишіть свої особисті дані (у разі подання повідомлення із зазначенням відомостей про себе), у разі якщо ви хочете повідомити анонімно, для зручності залиште електронну пошту, на яку надійде ідентифікатор;</li>
								<li>із переліку виберіть, про яке правопорушення ви бажаєте повідомити;</li>
								<li>зазначте джерело отримання інформації;</li>
								<li>напишіть обставини правопорушення та прикріпіть підтверджуючі матеріали;</li>
								<li>поставте відмітку про ознайомлення з правилами порталу.</li>							
							</ul>
							<span className="accordion-slide--list">
								Після завершення всіх дій ви отримаєте унікальний ідентифікатор, за допомогою якого зможете перевіряти статус повідомлення, набуття вами статусу викривача та користуватися правами і гарантіями викривача у разі набуття вами такого статусу.
							</span>
							</div>
						</div>
					</div>

					<div className="accordion-item">
						<div className="accordion-btn"  onClick={()=>onClick(2)}>
							<span className="accordion--title">Строки розгляду повідомлення</span>
							<i className={isOpen[2] ? "ico_acord_slide active" : "ico_acord_slide"} ></i>
						</div>

						<div className="accordion-slide" aria-expanded={isOpen[2]} >
						<div className="accordion-slide-main">
							<span className="accordion-slide--list">Попередній розгляд повідомлення здійснюється у строк не більше ніж 10 робочих днів з дня внесення цієї інформації до порталу.</span>
							
							<p className="accordion-slide--list">
							У разі якщо під час попереднього розгляду повідомлення встановлено, що воно не відповідає вимогам Закону України «Про запобігання корупції», його подальший розгляд здійснюється у порядку, визначеному для розгляду звернень громадян у терміни,  встановлені в Законі України «Про звернення громадян».
							</p>
							</div>
						</div>
					</div>

					<div className="accordion-item">
						<div className="accordion-btn"  onClick={()=>onClick(3)}>
							<span className="accordion--title">Строки інформування викривача</span>
							<i className={isOpen[3] ? "ico_acord_slide active" : "ico_acord_slide"} ></i>
						</div>

						<div className="accordion-slide" aria-expanded={isOpen[3]} >
						<div className="accordion-slide-main">
							<span className="accordion-slide--list">Особі, яка здійснила повідомлення, надається детальна інформація про результати попереднього розгляду, а також дисциплінарного провадження (якщо воно проводилося) у триденний строк з дня завершення відповідного розгляду чи провадження.</span>
							</div>
							
						</div>
					</div>

					<div className="accordion-item">
						<div className="accordion-btn"  onClick={()=>onClick(4)}>
							<span className="accordion--title">Що таке ідентифікатор</span>
							<i className={isOpen[4] ? "ico_acord_slide active" : "ico_acord_slide"} ></i>
						</div>

						<div className="accordion-slide" aria-expanded={isOpen[4]} >
						<div className="accordion-slide-main">
							<span className="accordion-slide--list">Це автоматично наданий засобами порталу унікальний код повідомлення про корупцію, з метою анонімізації такого повідомлення та ідентифікації в системі порталу. За допомогою ідентифікатора заявник зможе перевірити стан розгляду повідомлення, набуття статусу викривача та користуватися правами і гарантіями викривача у разі набуття такого статусу.</span>
							</div>
						</div>
					</div>

					<div className="accordion-item">
						<div className="accordion-btn"  onClick={()=>onClick(5)}>
							<span className="accordion--title">Для чого потрібний ідентифікатор</span>
							<i className={isOpen[5] ? "ico_acord_slide active" : "ico_acord_slide"} ></i>
						</div>

						<div className="accordion-slide" aria-expanded={isOpen[5]} >
						<div className="accordion-slide-main">
							<span className="accordion-slide--list">Ідентифікатор забезпечує особі, яка подала повідомлення про можливі факти корупційних або пов’язаних з корупцією правопорушень, інших порушень Закону України «Про запобігання корупції», анонімність та конфіденційність.
									За допомогою ідентифікатора особа може:</span>
							<ul className="accordion-slide--list">
							<li>перевірити стан розгляду повідомлення, набуття статусу викривача;</li>
									<li>звернутися до центру надання безоплатної правової допомоги для отримання безоплатної правової допомоги їй як викривачу;</li>
									<li>звернутися до уповноваженого підрозділу з питань запобігання та виявлення корупції або до Національного агентства з питань запобігання корупції за захистом її прав як викривача;</li>
									<li>звернутися до правоохоронних органів для здійснення організаційно-технічних та інших заходів, спрямованих на захист життя, житла, здоров'я та майна цих осіб від протиправних посягань, з метою створення необхідних умов для належного відправлення правосуддя;</li>
									<li>реалізувати інші права та гарантії викривача.</li>							</ul>
									</div>
						</div>
					</div>

					<div className="accordion-item">
						<div className="accordion-btn"  onClick={()=>onClick(6)}>
							<span className="accordion--title">Як викривачу отримати захист?</span>
							<i className={isOpen[6] ? "ico_acord_slide active" : "ico_acord_slide"} ></i>
						</div>

						<div className="accordion-slide" aria-expanded={isOpen[6]} >
						<div className="accordion-slide-main">
							<span className="accordion-slide--list">Викривач може звернутися за захистом:</span>
							<ul className="accordion-slide--list">
								<li>до правоохоронних органів – за наявності загрози життю, житлу, здоров’ю та майну викривача, його близьких осіб;</li>
								<li>до уповноваженого підрозділу (уповноваженої особи) з питань запобігання та виявлення корупції в установі/ юридичній особі – для захисту трудових прав, у т.ч. захисту від застосування негативних заходів впливу з боку керівника або роботодавця;</li>
								<li>до Національного агентства з питань запобігання корупції – для забезпечення правового та іншого захисту, перевірки дотримання законодавства з питань захисту викривачів, захисту трудових прав, притягнення до відповідальності винних у порушенні прав викривачів;</li>
								<li>до Центрів безоплатної правової допомоги – для отримання безоплатної правової допомоги;</li>
								<li>до суду – для захисту своїх прав і свобод.</li>
							</ul>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<Banner />
		</section>
    )
}