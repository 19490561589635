import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

//import './index.css';

function handleClick(event) {
  //event.preventDefault();
  //sconsole.info('You clicked a breadcrumb.');
}

export function MainBreadcrumbs(props) {

  const { path, current } = props;
  return (
    <Breadcrumbs aria-label="breadcrumb">
      { path.map(el=><Link color="inherit" href={el.link} onClick={handleClick}>
        <span className="breadcrumb-path" >{el.title}</span>
      </Link>)}
      <Typography color="textPrimary">{current.title}</Typography>
    </Breadcrumbs>
  );
}
