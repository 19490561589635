import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import big_search from './big_search.svg';

import { ResultFind } from './result';

import { TransitionsModal } from '../modal';


const useStyles = makeStyles((theme)=>({

    sect:{
        paddingTop: '85px',
        marginLeft: '50px',
        marginRight: '50px'
    },
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        //paddingLeft: '15px',
        //paddingRight: '15px'
    },
    results_cont: {
        //width: '100%',
        padding: '50px',
        position: 'relative',
        background: '#f2f9fd',
//background: "linear-gradient(90deg,#4376B3 0%,#477DBD 34.9%,#234260 100%);",
//color: "white",
        borderRadius: '8px',
        //border: '2px solid #3973A1',
        boxShadow: '0px 2px 8px rgba(92, 99, 105, 0.15)',
        '&::before':{
            content: '""',
            width: '270px',
            height: '100%',
            display: 'block',
            position: 'absolute',
            top: '0',
            right: '60px',
            backgroundImage: `url(${big_search})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            opacity: 0.1,
            'z-index': 1
        }
    },
    sh_title:{
        position: 'relative',
        fontWeight: '700',
        color: '#212121',
        '&::before':{
            content: '""',
            width: '8px',
            height: '100%',
            display: 'block',
            position: 'absolute',
            left: '-20px',
            borderRadius: '6px',
            background: '#fcda4b'
        }
    },
    results_body:{
        paddingTop: '35px',
        display: '-webkit-box',
        display: '-ms-flexbox',
        display: 'flex',
        '-webkit-box-orient': 'vertical',
        '-webkit-box-direction': 'normal',
        '-ms-flex-direction': 'column',
        flexDirection: 'column'
    },
    results_title:{
        color: '#212121',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '500'
    },
    results_caption:{
        paddingTop: '4px',
        color: '#6d727c',
        fontSize: '14px',
        lineHeight: '21px'
    },
    results_form:{
        paddingTop: '8px',
        display: '-webkit-box',
        display: '-ms-flexbox',
        display: 'flex',
        '-webkit-box-orient': 'horizontal',
        '-webkit-box-direction': 'normal',
        '-ms-flex-direction': 'row',
        flexDirection: 'row',
    },
    input_group: {
        width: '100%',
        maxWidth: '480px',
        marginRight: '24px',
        display: 'flex',
        flexDirection: 'column'
    },
    input_1:{
        height: '46px',
        padding: '0 16px',
        display: '-webkit-box',
        display: '-ms-flexbox',
        display: 'flex',
        '-webkit-box-pack': 'start',
        '-ms-flex-pack': 'start',
        'justify-content': 'flex-start',
        '-webkit-box-align': 'center',
        '-ms-flex-align': 'center',
        alignItems: 'center',
        background: '#fff',
        border: '1px solid #ccc',
        '-webkit-box-sizing': 'border-box',
        boxSizing: 'border-box',
        borderRadius: '3px',
        '-webkit-transition': '.2s',
        '-o-transition': '.2s',
        'transition': '.2s'
    },
    btn_yellow:{
        padding: '8px 24px',
        display: 'inline-block',
        color: "#212121",
        background: '#fcda4b',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '500',
        textDecoration: 'none',
        border: 'none',
        borderRadius: '8px',
        '-webkit-transition': '.2s',
        '-o-transition': '.2s',
        transition: '.2s',
        cursor: 'pointer'
    }


}));


export function FindResult(props){
    const classes = useStyles();

    const [resFind, setResultFind] = useState({});

    const [findStr, setFindStr] = useState('');

    const [shAlertLength, setAlertLength ] = useState(false);

    const [shAlertNotFind, setAlertNF ] = useState(false);


    const onChange = (e)=>{
        setFindStr(e.target.value);
        if(Object.keys(resFind).length > 0) setResultFind({});
    }


    const dataOrganList = async ()=> {

        if( ( findStr.length !=14 ) && ( findStr.length !=16 ) ) return setAlertLength(true);

        const url = `/api/anketa/${findStr}`;
        const f = await fetch(url);

        if(f.ok) {
            const data =  await f.json();
            if(data && data.data && data.data.length == 0) return setAlertNF(true);
            setResultFind(data.data);
        } else {
            alert('Вибачте, виникла помилка')
        }
    
    }


    return (
        <section className={classes.sect}>
        <TransitionsModal open={shAlertLength} caption="Формат ідентифікатору" text="Ідентифікатор повідомлення має складатись з 14 знаків" handleClose={()=>setAlertLength(false)} />
        <TransitionsModal open={shAlertNotFind} caption="Результат пошуку" text="Повідомлення за таким ідентифікатором не знайдено" handleClose={()=>setAlertNF(false)} />
		<div className={classes.container}>
			<div className={classes.results_cont}>
				<h2 className={classes.sh_title}>Перевірити результати розгляду повідомлення</h2>
				<div className={classes.results_body}>
					<span className={classes.results_title}>Ідентифікатор повідомлення</span>
					<span className={classes.results_caption}>14-ти значний номер, який ви отримали при поданні повідомлення</span>
					<div className={classes.results_form}>
						<div className={classes.input_group}>
							<input id="search-result" className={classes.input_1} onChange={onChange} type="text" placeholder="Введіть ідентифікатор повідомлення" />
						</div>
						<button className={classes.btn_yellow} onClick={dataOrganList} id="search-result">Перевірити</button>
					</div>
				</div>
                <ResultFind data={resFind}/>
			</div>
		</div>
	</section>

    )
}