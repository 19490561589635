import React, { useState, useEffect} from 'react';

import { PieChartDetail } from './pie_chart_det';

import { AutoCompliteComboBox } from '../../../../components/anketa/widgets/autocomplite';

import Select from 'react-select';


import './style.css';

import channels from '../../data/channels.json';

import periods from '../../data/periods.json';

import { MAP_INFO } from '../map/regions';

import typesankets  from '../../data/typesankets.json';

import sourceankets from '../../data/source.json';
import personankets from '../../data/person.json';

import statuses_msg from '../../data/statuses_msg.json';
import statuses_psr from '../../data/statuses_prs.json';


const t_data = [
	{ value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
	{ value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
	{ value: 'purple', label: 'Purple', color: '#5243AA' },
	{ value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
	{ value: 'orange', label: 'Orange', color: '#FF8B00' },
	{ value: 'yellow', label: 'Yellow', color: '#FFC400' },
	{ value: 'green', label: 'Green', color: '#36B37E' },
	{ value: 'forest', label: 'Forest', color: '#00875A' },
	{ value: 'slate', label: 'Slate', color: '#253858' },
	{ value: 'silver', label: 'Silver', color: '#666666' },
];

const style_select_central = {
	main: {
		width: '100%',
		maxWidth: 'calc((100% / 3) - 22px)',
		marginRight: '32px',
		marginBottom: '35px'
	},
	label: {
		display: 'inline-block',
		marginBottom: '4px',
		color: '#212121',
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: 500
	},
	select:{
		lineHeight: '35px'
	}
}


const regions = ['Всі'].concat(MAP_INFO.map(el=>el.title).sort());

const periods_values = {
	"Рік": 365 * 24 * 60 * 60 * 1000,
	"Місяць": 30 * 24 * 60 * 60 * 1000,
	"Тиждень": 7 * 24 * 60 * 60 * 1000, 
	"День":  1 * 24 * 60 * 60 * 1000
};

const const_time = 'T23:59:59';

const cur_date = new Date().toISOString().split('T')[0];

const date_ = (period) =>{
	if(!periods_values[period]) return "";
	return new Date(new Date(cur_date + const_time).getTime() - periods_values[period]).toISOString().split('T')[0];
}


export function AnaliticBlock2(props){

	const { organizations, statistics, setCentral, central, setTerritorial, setRegion, setPerson, setSource, setTypeAnkets, setChannel, setDateFrom, setDateTo, setStatusMsg, setStatusPrs, date_from, date_to } = props;

	const {channel, typeAnkets, source,	status_message, status_person } = props;

	const [show_addblock, setShowaddblock] = useState(false);

	const [period, setPeriod] = useState(date_("Рік"));

	
	const setNewPeriod = ()=>{

		setDateFrom(date_(period));
		setDateTo("");
	};

	const setDateFromCheck = (date)=>{
		if(date_to && ( new Date(date) > new Date(date_to) ) ) setDateTo(''); 
		setDateFrom(date);
	}


	const setDateToCheck = (date)=>{
		const par_date = date_from && ( new Date(date) < new Date(date_from) ) ? date_from : date; 
		setDateTo(par_date);
	}
	
	useEffect(setNewPeriod, [period]);
	
	const opt_terrotorial = [<option value="Всі">Всі</option>].concat(organizations && organizations.territorial && organizations.territorial[central] && organizations.territorial[central].map(row=><option value={row.edrpou}>{row.name}</option>))

	const opt_person = [<option value="Всі">Всі</option>].concat(personankets.map(row=><option value={row.value}>{row.name}</option>));
	const opt_source = [<option value="Всі">Всі</option>].concat(sourceankets.map(row=><option value={row.value}>{row.name}</option>));

	const opt_statuses_msg = [<option value="Всі">Всі</option>].concat(statuses_msg.map(row=><option value={row.value}>{row.name}</option>));
	const opt_statuses_prs = [<option value="Всі">Всі</option>].concat(statuses_psr.map(row=><option value={row.value}>{row.name}</option>));

	const opt_typesankets = [<option value="Всі">Всі</option>].concat(typesankets.map(row=><option value={row.value}>{row.name}</option>))

	const set_organization = (data)=>{
		if(data && data.value) setCentral(data.value);
		setTerritorial('Всі');
	}


	//if(!organizations || !organizations.central || organizations.central.length < 1 ) return null;

	const options = organizations && organizations.central && organizations.central.map(c=>({label: c.name, value:c.edrpou}) ) || [];


return (
    <div>
    	<section className="sect full_form">
		{false && <div className="container">
			<h2 className="sh-title">За типами повідомлень</h2>
		</div>}
		<div className="full_form-container">
			<div className="container">
				<div className="full_form-cont">
					<div className="input-group_1" style={style_select_central.main}>
						<label for="" style={style_select_central.label}>Центральний орган</label>
						{false && <select name="" id="" className="select-f" data-placeholder="Усі центральні органи" onChange={(e)=> { setCentral(e.target.value); setTerritorial('Всі')} }>
						  {[<option value="Всі">Всі</option>].concat(organizations && organizations.central.map(row=><option value={row.edrpou}>{row.name}</option>)) }
						</select>}
						{false && <AutoCompliteComboBox options={organizations && organizations.central && organizations.central.map(c=>c.edrpou) }  optionsName={organizations && organizations.central && organizations.central.map(c=>c.name) }  onChange={ (e, v) => { console.log(`org value  ${JSON.stringify(v || {})}`); setCentral((v && v.value) || {} ); setTerritorial('Всі') }} value={JSON.stringify(organizations && organizations.central && organizations.central.find(c=>c.edrpou == central) || {})} label="Оберіть"/>}

						{false && <input list="org_list_anal"/>}
						{false && <datalist id="org_list_anal">
							{organizations && organizations.central.map(row=><option value={row.edrpou}>{row.name}</option>)}
						</datalist>}
						<div style={style_select_central.select}>
						<Select 
							options={options} 
							isSearchable={true}
							onChange={set_organization}
							isDisabled={false} 
							value={options.find(el=>el.value == central)}
							placeholder="Всі"
						/>
						</div>
					</div>

					<div className="input-group">
						<label for="">Територіальний орган</label>
						<select name="" id="" className="select-f" data-placeholder="Усі територіальні органи" onChange={(e)=>setTerritorial(e.target.value)}>
						  { opt_terrotorial }
							
						</select>
					</div>

					<div className="input-group">
						<label for="">Хто вчинив правопорушення</label>
						<select name="" id="" className="select-f" data-placeholder="Будь хто" onChange={(e)=>setPerson(e.target.value)}>
						  { opt_person }
						</select>
					</div>

					<div className="input-group">
						<label for="">Період даних</label>
						<select name="" id="" className="select-f" data-placeholder="За весь час" onChange={(e)=>setPeriod(e.target.value)}>
						  {periods.map(el=><option value={el}>{el}</option>)}
						</select>
					</div>

					<div className="input-group input-group-date">
						<div className="input-group--date">
							<label for="">Період з</label>
							<input type="date" max={cur_date} value = {date_from} onChange={(e)=>setDateFromCheck(e.target.value)}/>
						</div>

						<div className="input-group--date">
							<label for="">по</label>
							<input type="date" max={cur_date} value={date_to} onChange={(e)=>setDateToCheck(e.target.value)}/>
						</div>
						
					</div>

					<div className="input-group">
						<span onClick={()=>setShowaddblock(!show_addblock)}>{show_addblock ? "Сховати додаткові фільтри <-": "Показати додаткові фільтри ->" }</span>
					</div>

					{ show_addblock && <>

					{true && <div className="input-group">
						<label for="">Вид правопорушення</label>
						<select name="" id="" className="select-f" data-placeholder="Усі типи" value = {typeAnkets} onChange={(e)=>setTypeAnkets(e.target.value)}>
						  { opt_typesankets}
						</select>
					</div>}

					<div className="input-group">
						<label for="">Канал отримання</label>
						<select name="" id="" className="select-f" data-placeholder="Усі канали" value={channel} onChange={(e)=>setChannel(e.target.value)}>
						  {channels.map(row=><option value={row}>{row}</option>)}
						</select>
					</div>

					<div className="input-group">
						<label for="">Джерело отримання інформації</label>
						<select name="" id="" className="select-f" data-placeholder="Будь хто" value={source} onChange={(e)=>setSource(e.target.value)}>
						  { opt_source }
						</select>
					</div>

					<div className="input-group">
						<label for="">Статус повідомлення</label>
						<select name="" id="" className="select-f" data-placeholder="Усі статуси" value={status_message} onChange={(e)=>setStatusMsg(e.target.value)}>
						  { opt_statuses_msg }
						</select>
					</div>

					<div className="input-group">
						<label for="">Статус викривача</label>
						<select name="" id="" className="select-f" data-placeholder="Усі статуси" value={status_person} onChange={(e)=>setStatusPrs(e.target.value)}>
						  { opt_statuses_prs }
						</select>
					</div>
					</>}
				</div>
			</div>
		</div>
	</section>

    <PieChartDetail analitica={statistics.analitica} count = {statistics.count}/>
    </div>
)
}