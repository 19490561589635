export  async function dataOrganList() {

    const url = '/api/organization';
    const f = await fetch(url);
    const data =  await f.json();

    const central = data.data.filter(row=>!row.isTerritorialAuthorities);

    const territorial = {};

    data.data.filter(row=>row.isTerritorialAuthorities).forEach(row=>{
      
      if(!territorial[row.edrpouCentralAuthorities]) territorial[row.edrpouCentralAuthorities] = [];

      territorial[row.edrpouCentralAuthorities].push(row);

    });

  
    if(!data.data || data.data.length < 1 ) return 'error';
    return ({ data: data.data, central: central, territorial: territorial});

  }