import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { Header } from '../../components/header';

import { Footer1 } from '../../components/footer1';

import { MainBreadcrumbs } from '../../components/breadcrumb';

import MaterialTable from "material-table";

import { dataOrganList } from "../../actions/organizations";



import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  body:{
      maxWidth: '1280px',
      minHeight: '600px',
      margin: 'auto',
      padding: '40px 85px 0px 85px'
  },
  form:{
      //padding: '50px'
  }
}));



const path_br = [
    { title: "Головна", link: "/" }
];

const cur_br = { title: "Організації, підключені до порталу", link: "" }

const columns = [
    { title: 'Найменування', field: 'name' },
    { title: "ЄДРПОУ", field: 'edrpou' },
    { title: "Вид організації", field: 'type_name' },
    { title: 'Регіон', field: 'organizationRegion' },
    { title: 'Спеціальна телефонна лінія', field: 'phoneOrganizationView' }
  ];

const options = {
    filtering: true,
    search: false,
    rowStyle: x => {
        const stRow = {fontSize: 14};
        if (x.tableData.id % 2) {
             stRow['backgroundColor'] = "#f2f2f2"
        }

        return stRow;
    },
    actionsColumnIndex: -1
  };

const localization={
    pagination: { 
        labelRowsSelect: "записів",
        labelDisplayedRows: '{from}-{to} від {count}' 
    },
    header:{
        actions: "Додатково"
    }
    
};



export function OrganizationsPage(){

        const history = useHistory();

        const actionsRow = [{
            icon: ()=><span style={{fontSize: '14px'}}>Аналітика</span>,
            tooltip: 'Аналітика повідомлень',
            onClick: (event, rowData)=> history.push({
                pathname: '/analitica',
                state:{
                    central: rowData.edrpou,
                    organizations: organ_list
                } 
            })
        }];

        const classes = useStyles();

        const [ data, setData] = useState([]);

        const [organ_list, setOrganiz] = useState([]);

        
        const updData = async ()=>{
            const data_load = await dataOrganList();

            setOrganiz(data_load.data);

            setData(data_load.data.filter(el=>!el.isTerritorialAuthorities ||(el.isTerritorialAuthorities && el.edrpouCentralAuthorities ) ).map(row=>{
                const res = {};
                res['name'] = row.name;
                res['type_name'] = row.type.name;
                res['organizationRegion'] = row.organizationRegion.name;
                res['phoneOrganizationView'] = row.phoneOrganizationView;
                res['edrpou'] = row.edrpou;
                res['isTerritorialAuthorities'] = row.isTerritorialAuthorities || false;
                res['edrpouCentralAuthorities'] = row.edrpouCentralAuthorities;
                return res;
            }));
        };
        
        useEffect(()=>{
            if(data.length > 0) return;
            updData();
        });


        return (
            <div >
                <Header short={true} />
                <div className={classes.body}>
                    <MainBreadcrumbs path={path_br} current={cur_br}/>
                    <LabelBlock/>
                    <div className={classes.form}><Content data={data} actionsRow={actionsRow}/></div>
                </div>
                <Footer1 />
            </div>
        )
}


function Content(props){

    const { data, actionsRow } = props;

    return (
<div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={columns}
          data={data}
          parentChildData={(row, rows) => rows.find(a => a.isTerritorialAuthorities && (a.edrpou === row.edrpouCentralAuthorities) )}
          title=""
          options={options}
          localization={localization}
          actions={actionsRow}
        />
      </div>
        )
}

function LabelBlock(){
    return(
        <div className="anketa-header-titul">
            <span>Організації, підключені до порталу</span>
        </div>
    )
}