import React from 'react';

import './style.css';

import user_img from './img/r1.svg'; //'./img/user.svg';

import message from './img/r2.svg'; //'./img/message.svg';
import indificator from './img/r3.svg'; //'./img/indificator.svg';
import process from './img/r4.svg'; //'./img/process.svg';
import site from './img/r5.svg'; //'./img/site.svg';


export function MovMsgs(){
    return(	
		<section className="sect-blue portach">
		<div className="container">
			<div className="portach-cont">
				<h2 className="sh-title">Рух повідомлення на порталі</h2>
				<div className="portach-body">

				<div className="portach-progress">
          <div className="progr-main"></div>
          <div className="progr-green"></div>
          <div className="progr-circle">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>

					<div className="portach-item">
						<div className="portach-item--img">
							<img src={user_img} alt="Особа, вносить на портал інформацію про факти корупції" className="portach-item--img" />
						</div>
						<span className="portach-item--caption">Особа вносить на портал інформацію про факти корупції</span>
					</div>

					<div className="portach-item">
						<div className="portach-item--img">
							<img src={message} alt="Інформація формується в повідомлення" className="portach-item--img" />
						</div>
						<span className="portach-item--caption">Інформація формується в повідомлення</span>
					</div>

					<div className="portach-item">
						<div className="portach-item--img">
							<img src={indificator} alt="Система призначає ідентифікатор повідомлення" className="portach-item--img" />
						</div>
						<span className="portach-item--caption">Система призначає ідентифікатор повідомлення</span>
					</div>

					<div className="portach-item">
						<div className="portach-item--img">
							<img src={process} alt="Здійснюється розгляд повідомлення" className="portach-item--img" />
						</div>
						<span className="portach-item--caption">Здійснюється розгляд повідомлення</span>
					</div>

					<div className="portach-item">
						<div className="portach-item--img">
							<img src={site} alt="Особа отримує результати розгляду повідомлення" className="portach-item--img" />
						</div>
						<span className="portach-item--caption">Особа отримує результати розгляду повідомлення</span>
					</div>

				</div>
			</div>
		</div>
	</section>
		)
}