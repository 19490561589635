export function news_notification(){

    fetch('https://nazk.gov.ua/wp-admin/admin-ajax.php', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: 'action=notify&lang=uk'
    })
        .then((response)=>response.json())
        .then((html)=>{
            const el = document.querySelector('.data_notify');

            if(!html.html) return;

            let res = html.html.replace('document.addEventListener("DOMContentLoaded", () => {', "");
            let pos = res.lastIndexOf('});');
            let otherchar = '';
            res = res.substring(0,pos) + otherchar + res.substring(pos+3);

            let script_code = res.split('<script>')[1].split('</script>')[0];

            let html_code = res.split('<script>')[0];

            el.insertAdjacentHTML('afterbegin', html_code);

            let script_node = document.createElement('script');

            script_node.textContent = script_code;

            document.body.appendChild(script_node);

            
        })
        .catch((error)=>{
            console.log('error get news notification');
        })

}