import React, { useState, useEffect } from 'react';
import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const theme = createMuiTheme({
  overrides: {
   MuiStepIcon: {
    root: {
      '&$completed': {
        color: '#15CF74',
      },
      '&$active': {
        color: '#007CBA',
      },
    },
    active: {},
    completed: {},
  },
  MuiStepConnector: {
    horizontal:{
      '&$completed': {
        color: '#697386',
      },
      '&$active': {
        color: '#007CBA',
      },
      'border-top-width': '4px'
    }
  }
}})

const useStyles = makeStyles((theme) => ({
  root: {
    //width: '100%',
    background: '#F2F9FD',
    borderRadius: '8px'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));



export function StepperV(props) {
  const classes = useStyles();
  const { propsActiveStep, propsSteps } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [skipped, setSkipped] = useState(new Set());
  //const steps = getSteps();

  React.useEffect(()=>{
      setActiveStep(propsActiveStep);
  },[propsActiveStep]);

  React.useEffect(()=>{
      setSteps(propsSteps);
  },[propsSteps]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };


  return (
    <div className={classes.root}>
    <MuiThemeProvider theme={theme}>
      <Stepper activeStep={activeStep} classes={classes}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      </MuiThemeProvider>
    </div>
  );
}
