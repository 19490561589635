import React from 'react';

//import Link from '@material-ui/core/Link';


import icn_doc_img from './img/p1.svg'; //'../../img/icn_doc.svg';
import icn_message from './img/p2.svg'; //'../../img/icn_message.svg';
import icn_sheild from './img/p3.svg'; //'../../img/icn_sheild.svg';
import icn_settings from './img/p4.svg'; //'../../img/icn_settings.svg';


import './style.css';

const urls_doc = [
	`/docs/Пам'ятка_права_викривача.pdf`,
	`/docs/Пам'ятка_про_ що_повідомити.pdf`,
	`/docs/Пам'ятка_куди_повідомити.pdf`,
	`/docs/Пам'ятка_порядок_розгляду_повідомлень.pdf`
]

export function NotesInfo(){
    return(
        	<section className="sect attractor">
		<div className="container">
			<div className="attractor-cont">
				<h2 className="sh-title">Пам’ятки викривача</h2>
				<div className="attractor-body">

					<div className="attractor-item">
						<a href={urls_doc[0]} target="_blank" download>
						<div className="attractor-item--img-cont">
							<img src={icn_doc_img} alt="Права викривача" className="attractor-item--img"/>
						</div>
						<span className="attractor-item--title">Права викривача</span>
                        
						
						<div className="attractor-item--download">
							<i className="download-icon"></i>
							<span>Завантажити</span>
						</div>
						</a>
					</div>

					<div className="attractor-item">
					<a href={urls_doc[1]} target="_blank" download>
						<div className="attractor-item--img">
							<img src={icn_message} alt="Про що можна повідомити" className="attractor-item--img"/>
						</div>
						<span className="attractor-item--title">Про що можна повідомити</span>
						<div className="attractor-item--download">
						
							<i className="download-icon"></i>
							<span>Завантажити</span>
						</div>
						</a>
					</div>

					<div className="attractor-item">
					<a href={urls_doc[2]} target="_blank" download>
						<div className="attractor-item--img">
							<img src={icn_sheild} alt="Куди повідомити" className="attractor-item--img"/>
						</div>
						<span className="attractor-item--title">Куди повідомити</span><br/>
						<div className="attractor-item--download">
							
								<i className="download-icon"></i>
								<span>Завантажити</span>
						</div>
						</a>
					</div>

					<div className="attractor-item">
					<a href={urls_doc[3]} target="_blank" download>
						<div className="attractor-item--img">
							<img src={icn_settings} alt="Порядок розгляду повідомлень" className="attractor-item--img"/>
						</div>
						<span className="attractor-item--title">Порядок розгляду повідомлень</span>
						<div className="attractor-item--download">
							<i className="download-icon"></i>
							<span>Завантажити</span>
						</div></a>
					</div>

				</div>
			</div>
		</div>
	</section>
    )
}