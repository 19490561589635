import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Grid from '@material-ui/core/Grid';


export const HelpInfoAnketa = forwardRef((props, ref)=>{

    const {isShow, mainText, classes, dependText, dependKey, formData} = props;

    const [text_add_info, setTextAddInfo] = useState('');

    useImperativeHandle(ref, () => ({
        setText(text){
            setTextAddInfo(text);
        }
    
    }));

    return (<Grid item xs={6} lg={6}>
        {isShow  && <div className={classes.help_info_main}>
          {isShow.main_info && <div className={classes.help_info_text} dangerouslySetInnerHTML={{ __html: mainText}}></div>}
          {isShow.addInfo && text_add_info && <div className={classes.help_info_text} dangerouslySetInnerHTML={{ __html: text_add_info}} />}
          {dependKey && formData && formData[dependKey] && dependText[formData[dependKey]] && <div className={classes.help_info_text} dangerouslySetInnerHTML={{ __html: dependText[formData[dependKey]]}}></div>}
        </div>}
      </Grid>)
});