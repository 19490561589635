import React from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter,
  Switch,
  Route
} from "react-router-dom";

import './css/index.css';

import ScrollToTop from './components/ScrollTop';

import App from './App';
//import reportWebVitals from './reportWebVitals';
import { MainPage } from './pages/main';
import { HeadPage } from './pages/head';

import { AnaliticaPage } from './pages/analitica';

import { VykryvachPage } from './pages/vykryvach';

import { OrganizationsPage } from './pages/organiztions';

ReactDOM.render(
  <React.StrictMode>
       <HashRouter>
        <Switch>
          <Route exact path="/anketa" component={MainPage}/>
          <Route exact path="/" component={HeadPage} />
          <Route exact path="/analitica" component={AnaliticaPage} /> 
          <Route exact path="/vykryvach" component={VykryvachPage} />
          <Route exact path="/organizations" component={OrganizationsPage} />
        </Switch>
    </HashRouter>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
