import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Header } from '../components/header';

import { HeaderT } from '../components/header/t_header';

//import { Body } from '../components/body';
import { Footer } from '../components/footer';

import { Footer1 } from '../components/footer1';

import { TestStepper } from '../components/anketa';

import { MainBreadcrumbs } from '../components/breadcrumb';

import { Typography } from '@material-ui/core';

//import './main.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  body:{
      maxWidth: '1280px',
      minHeight: '600px',
      margin: 'auto',
      padding: '20px 5px 5px 0px',
      [theme.breakpoints.up('md')]: {
        padding: '40px 85px 0px 85px',
        
      }
  },
  form:{
      //padding: '50px'
  }
}));

const path_br = [
    { title: "Головна", link: "/" }
];

const cur_br = { title: "Повідомлення про корупцію", link: "" }

export function MainPage(props) {

    const classes = useStyles();

    const [visibleLabel, setVisibleLabel] = useState(true);

    return (
        <div >
            <Header short={true} />
            <div className={classes.body}>
                <MainBreadcrumbs path={path_br} current={cur_br}/>
                <LabelBlock visible={visibleLabel} />
                <div className={classes.form}><TestStepper setVisibleLabel={setVisibleLabel}/></div>
            </div>
            <Footer1 />
        </div>
    )
}

function LabelBlock(props){

    const { visible } = props;

    return(
        visible && <div className="anketa-header-titul">
            <span>Повідомлення про корупцію</span>
        </div>
    )
}