import React from "react";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";

export  function AutoCompleteMultiSelect(props) {

    const { items, itemNames, onChange, value, label, placeholder, setInfo } = props;

  return (
    <Autocomplete
      multiple
      id="fixed-tags-demo"
      size="small"
      clearText="Прибрати обрані"
      options={items}
      getOptionLabel={option => option}
      onChange={(event, value)=>onChange(value)}
      value={value}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={<Typography style={{whiteSpace: 'normal'}}>{option}</Typography>}
            {...getTagProps({ index })}

            onMouseEnter={()=>setInfo(value[index])}
            onMouseLeave={()=>setInfo()}
            //disabled={index === 0}
            style={{height:"100%"}}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}