import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { keyApi } from '../../../conf/address.json';

export const AddressAutocompleate = (props) => {
  
  const { value, setValue } = props;

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={keyApi}
        apiOptions={
            {
                language:'uk',
                region:'uk'
            }
        }
        selectProps={{
          value,
          onChange: setValue,
        }}
      />
    </div>
  );
}