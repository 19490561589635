import React, { useState, useEffect} from "react";

import { Grid, Typography, Link } from "@material-ui/core"; 

import './style.css';


const priority_st = [
    "АКТИВНИЙ", "НЕАКТИВНИЙ"
];

export function ResultFind(props){

    const { data } = props;

    const [info, setInfo] = useState({});

    const [ sh_detail_info, setDetail ] = useState(false);

    const inData = ()=>setInfo(data);

    const { isSpecialEntity } = info;

    const spec_org_st = {
        statusMessage: info.statusMessageSpecialEntity, 
        statusWhistleblower: info.statusWhistleblowerSpecialEntity, 
        nameDepartment: info.nameDepartmentSpecialEntity, 
        nameUser: info.nameUserSpecialEntity
    }

    const org_st = {
        statusMessage: info.statusMessage, 
        statusWhistleblower: info.statusWhistleblower, 
        nameDepartment: info.nameDepartment, 
        nameUser: info.nameOrganizationView
    }

    const priority_st_spec = priority_st.findIndex(el=>el == spec_org_st.statusWhistleblower);
    const priority_st_reg = priority_st.findIndex(el=>el == org_st.statusWhistleblower);

    const spec_hi = (priority_st_spec !== -1) && (priority_st_spec < priority_st_reg);

    const msg_info = spec_hi ? spec_org_st : org_st;

    const docPdf = spec_hi ? info.PDFfileStatusSpecialEntity && info.PDFfileStatusSpecialEntity.url : info.PDFfileStatus && info.PDFfileStatus.url;

    useEffect(()=>{
        
        if( (!data || !data.messageNumber) && !info.messageNumber) return;

        inData();
    },[data.messageNumber]);

    if(!info.messageNumber) return <div></div>;

    return(
        <div className="result-find">
            <Grid container>

                <Grid items xs={4}>
                    <ItemBlock label="Ідентифікатор повідомлення" value={info.messageNumber} />  
                </Grid>

                <Grid items xs={4}>
                    <ItemBlock label="Дата подання повідомлення" value={info.date && new Date(info.date).toLocaleDateString('uk')} /> 
                </Grid>

                <Grid items xs={4}>
                <div className="item-info">
                    </div>
                </Grid>

                <Grid items xs={4}>
                    <ItemBlock label="Орган, до якого було подане повідомлення" value={msg_info.nameDepartment} /> 
                </Grid>


                <Grid items xs={4}>
                    {false && msg_info.nameUser && <ItemBlock label="Особа, яка розглядає повідомлення" value={msg_info.nameUser}  /> } 
                </Grid>

                <Grid items xs={4}>
                <div className="item-info">
                    </div>
                </Grid>

                <Grid items xs={4}>
                    <ItemBlock label="Статус повідомлення" value={msg_info.statusMessage} color={msg_info.statusMessage == "ВІДХИЛЕНО" ? "pr": msg_info.statusMessage == "РОЗГЛЯНУТО" ? "ok" : "info"} t_text={ msg_info.statusMessage != "ВІДХИЛЕНО" ? "msg_ok": "msg_not" } /> 
                </Grid>


                <Grid items xs={4}>
                    <ItemBlock label="Статус викривача" value={msg_info.statusWhistleblower} color={msg_info.statusWhistleblower != "НЕАКТИВНИЙ" ?  "ok": "pr"} t_text={ msg_info.statusWhistleblower != "НЕАКТИВНИЙ" ?  "vyk_ok": "vyk_not"} /> 
                </Grid>

                <Grid items xs={4}>
                {docPdf && ( msg_info.statusMessage !== 'ВІДХИЛЕНО' ) && (msg_info.statusWhistleblower != "НЕАКТИВНИЙ") && <div className="item-info">
                    <div className="res-download-title"><Typography variant="body2">Документ</Typography></div>
                    <Link href={docPdf} target="_blank"><button className="res-download"><span className="res-download-text" >Підтвердження статусу</span></button></Link>
                    <div className="res-download-type">Файл PDF</div>
                </div>}
                </Grid>

                {false && <Grid items xs={12}>
                    <NotesBloc notes={info.nameNotes}/>
                </Grid>}

                {true && <Grid items xs={12}>
                    <DescBloc statusMessage={msg_info.statusMessage}/>
                </Grid>}

                <DetailInfo isSpecialEntity={isSpecialEntity} sh_detail_info={sh_detail_info} setDetail={setDetail}/>

                <HistoryInfo sh_detail_info={sh_detail_info} data={info}/>

            </Grid>
        </div>
    )
}

function ItemBlock(props){
    const { label, value, color, t_text} = props;

    const text = {

        msg_not: "Повідомлення про корупцію не відповідає вимогам Закону та не прийнято на розгляд/перевірку",

        msg_ok: "Повідомлення прийнято на розгляд/перевірку",

        vyk_not: "Ви не є викривачем",

        vyk_ok: "Ви є викривачем"

    };


    return(
        <div className="item-info">
                    <div className="item-label">{label}</div>
                    <div className={ "item-value" + ( color && color == "ok" ? 
                        " item-color-ok" :  color && color == "info" ? 
                            " item-color-info" : color && color == "info" ?
                                " item-color-pr": "" )  } 
                    >{value}</div>
                    {false && color && <div className="label-info">{t_text && text[t_text]}</div> }

        </div>
    )
}


function NotesBloc(props){
    const { notes } = props;

    if(!notes || notes.length == 0) return null;

    return(
        <div className="item-info">
            { false && <div className="notes-titul">Обгрунтування:</div>}
            <ul>
                {notes.map(el=><li>{el.notes}</li>)}
            </ul>
        </div>
    )
}

function DescBloc(props){
    const { statusMessage } = props;


    const info_msg_dsc = {
        'ВІДХИЛЕНО': "Наведена у повідомленні інформація не містить фактичних даних, які можуть бути перевірені, тому повідомлення відхилено без проведення попереднього розгляду.",
        'ПОВІДОМЛЕННЯ ПІДЛЯГАЄ РОЗГЛЯДУ ЯК ЗВЕРНЕННЯ ГРОМАДЯН': "Повідомлення не відповідає вимогам Закону України “Про запобігання корупції” і буде розглянуте як звернення громадян.",
        'РОЗГЛЯД ПРИПИНЕНО': "Розгляд повідомлення не належить до компетенції організації, тому його подальший розгляд припинено.",
        'РОЗГЛЯНУТО': "Якщо при поданні повідомлення ви зазначали контактні дані (поштову або електронну адресу), ви отримаєте розгорнуту відповідь.",
        //'НА РОЗГЛЯДІ': "Повідомлення містить факти корупційних або пов’язаних з корупцією правопорушень і буде розглядатись відповідно до КПК України або КУпАП, або як звернення викривача за захистом.",
        'НА РОЗГЛЯДІ': '',
        'РОЗГЛЯД ЯК ЗВЕРНЕННЯ ГРОМАДЯН': "Повідомлення не відповідає вимогам Закону України \"Про запобігання корупції\" і буде розглянуте як звернення громадян.",
        'РОЗГЛЯД ВІДПОВІДНО ДО КПК':'Інформація про можливі факти кримінальних правопорушень розглядається відповідно до КПК',
        'РОЗГЛЯД ВІДПОВІДНО ДО КУпАП':'Інформація про можливі факти адміністративних правопорушень, пов’язаних з корупцією, розглядається відповідно до КУпАП',
        'ЗВЕРНЕННЯ ЗА ЗАХИСТОМ':'Повідомлення містить інформацію про можливе порушення прав викривачів та розглядається як звернення викривача за захистом'
    }

    if(!statusMessage || statusMessage.length == 0 || !info_msg_dsc[statusMessage]) return null;

    return(
        <div className="item-info">
            <span>{info_msg_dsc[statusMessage]}</span>
        </div>
    )
}

function DetailInfo(props) {
    const { isSpecialEntity, sh_detail_info, setDetail } = props;

    const text_spec_forw = 'Повідомлення було перенаправлено на розгляд до спецсубʼєкту.';
    const desc = 'Детальніше >';

    if(!isSpecialEntity) return null;

    return(
        <Grid items xs={12}>
        <div className="detail-header">
            <span className="detail-header-label">{text_spec_forw}</span>
            <span className="detail-header-button" onClick={()=>setDetail(!sh_detail_info)}>{desc}</span>
        </div>
        </Grid>
    )
}

function HistoryInfo(props) {

    const { data, sh_detail_info } = props;

    const messageFinal = [
        'ВІДХИЛЕНО',
        'ПОВІДОМЛЕННЯ ПІДЛЯГАЄ РОЗГЛЯДУ ЯК ЗВЕРНЕННЯ ГРОМАДЯН',
        'РОЗГЛЯД ПРИПИНЕНО',
        'РОЗГЛЯНУТО',
        'ЗВЕРНЕННЯ ЗА ЗАХИСТОМ'
    ];

    if(!sh_detail_info) return null;

    const localDate = (date) => new Date(date).getTime() && new Date(date).toLocaleDateString('Uk') || '';

    const {
        date, 
        dateMessageWasViewedSpecialEntity, 
        statusMessageSpecialEntity,
        nameUserSpecialEntity,
        nameDepartmentSpecialEntity,
        statusWhistleblower,
        statusWhistleblowerSpecialEntity,
        nameOrganizationView,
        nameDepartment,
        statusMessage,
        dateSpecialEntity
    } = data;

    const viewInfo = {
        'Дата подання повідомлення': localDate(date),
        'Організація до якої було подане повідомлення': nameDepartment,
        'Статус повідомлення в організації до якої було подане повідомлення': statusMessage,
        'Статус викривача в організації до якої було подане повідомлення':statusWhistleblower,
        'Особа, що розглянула повідомлення в організації до якої було подане повідомлення': nameOrganizationView,
        'Дата перенаправлення повідомлення до спецсуб\'єкту': localDate(dateSpecialEntity),
        'Організація до якої було перенаправлено повідомлення': nameDepartmentSpecialEntity,   
        'Статус повідомлення в організації до якої було перенаправлено повідомлення': statusMessageSpecialEntity,
        'Статус викривача в організації до якої було перенаправлено повідомлення':statusWhistleblowerSpecialEntity,
        'Особа, що розглянула повідомлення в організації до якої було перенаправлено повідомлення': nameUserSpecialEntity,
    };

    const info = [];
    
    Object.keys(viewInfo).forEach((el, indx)=>{
        //if( ( indx == 4 ) && !messageFinal.includes(statusMessage) ) return null;
        //if( ( indx == 9 ) && !statusMessageSpecialEntity.includes(statusMessage) ) return null;
        info.push(<Grid items xs={6}><div className="detail-label">{el}</div></Grid>);
        info.push(<Grid items xs={1}>{' '}</Grid>)
        info.push(<Grid items xs={5}><div className="detail-data">{viewInfo[el]}</div></Grid>)
    });

    return (
        info.map(str=>str)
    )
}