import React, {useCallback, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


export function FileDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {

      const files = [];

      acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        const base64Str = reader.result;

        const nameFile = `;name=${file.name};size=${file.size};`;
        files.push(base64Str.replace(";base64", nameFile + "base64")) ;

        if(acceptedFiles.length == files.length) {
            props.onChange(files);
        }
      }
      reader.readAsDataURL(file)
      });
      
    // Do something with the files
  }, []);

  const {acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({onDrop,
    accept: '.pdf, .docx, .xlsx, .png, .jpg', minSize: 100, maxSize: 5242880, maxFiles: 5
  });

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size > 1000000 ? (file.size/1024/1024).toFixed(1) : (file.size/1024).toFixed(1) } {file.size > 1000000 ? 'Мб': 'Кб'}
    </li>
  ));

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container">
        <div {...getRootProps({style})}>
        <input {...getInputProps()} />
            <p>Перетягніть сюди файли або 
            оберіть його на своєму носієві</p>
        </div>
        { (acceptedFiles.length > 0) && <aside style={{ padding: '20px'}}>
            <h4>Файли</h4>
            <ul>{acceptedFileItems}</ul>
        </aside> }
    </div>
  )
}