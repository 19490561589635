import React, { } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function EditorWidget(props) {
 
  const { onChange, value } = props;

  var formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula'
    // 'image'
    // 'video'
  ];


  return (
    <ReactQuill 
        theme="snow" 
        value={value} 
        onChange={onChange}
        formats={formats}
    />
  );
}