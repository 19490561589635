import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography align="left" variant="body1">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export  function OrganizationTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      
        <Tabs value={value} 
            onChange={handleChange} 
            aria-label="simple tabs example" 
            indicatorColor="primary"
            variant="fullWidth"
            >
          <Tab label="Прозорі" {...a11yProps(0)} />
          <Tab label="Ефективні" {...a11yProps(1)} />
          <Tab label="Без коррупції" {...a11yProps(2)} />
          <Tab label="Приєднатись" {...a11yProps(3)} />
        </Tabs>
    
      <TabPanel value={value} index={0}>
      Відкритість створює довіру. Портал допомогає спростити подання заяви про коррупцію. Інформація щодо інцедентів допоможе швидко прймати рішення. 
      </TabPanel>
      <TabPanel value={value} index={1}>
        Витрачайте час та ресурси на основну діяльність. Портал створений для ефективної взаємодії. Процеси та зміст відповідают вимогам законодавства. 
      </TabPanel>
      <TabPanel value={value} index={2}>
        Це результат послідовної та виваженої політики. Портал допоможе Вам мати швидкий та надійний механізм протидії корупції в організації, територіальній громаді або власної структурі.  
      </TabPanel>

      <TabPanel value={value} index={3}>
        Як приєднатись. Покрокова інструкція.
      </TabPanel>
    </div>
  );
}
