import React,  { useState, useEffect }  from 'react';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';

import { CounterAnimated } from '../components/counter';

import './style.css';


const info_card = [
    { titul: "Надійшло повідомлень", field: "get", diff_label: "за останній місяць" },
    { titul: "Повідомлень в роботі", field: "inprogress", diff_label: "за останній місяць" },
    { titul: "Розглянуто повідомлень", field: "worked", diff_label: "за останній місяць" },
    { titul: "Повідомлень викривачів", field: "personstatus", diff_label: "за останній місяць" }
];

const data_default = {
    all:{
        get: "--",
        inprogress: "--",
        worked: "--",
        personstatus: "--"

    },
    month:{
        get: "-",
        inprogress: "-",
        worked: "-",
        personstatus: "-"

    }
};

const useStyles = makeStyles({
    diff_st: {
      backgroundColor:"rgba(21,207,116,.1)",
      borderRadius: "2px",
      width: "32px",
      height: "24px",
      color: "#15CF74",
      fontWeight: "bold",
    }
  });


export function BottomHeader(props){

    const { data } = props;

    //if(!data) return null;

    let {all, month} = data || data_default;

    if(!all || !month) {
        all = data_default.all;
        month = data_default.month;
    }

    return(
        <div className="bottom-header">
            <Grid container spacing={3}>
                { info_card.map(card=><Grid item xs={3}>
                    <InfoStatCard titul={card.titul} value={all[card.field]} diff={month[card.field]} diff_label={card.diff_label} />
                </Grid>) }
            </Grid>
        </div>
    )
}

function HeaderInfoCard(props){

    const classes = useStyles();

    const { titul, value, diff, diff_label } = props;
    return(
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
                <Typography >{titul}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h4" >{value}</Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography className={classes.diff_st}>
                    {diff}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography >{diff_label}</Typography>
            </Grid>
        </Grid>
    )
}

function InfoStatCard(props){

     const { titul, value, diff, diff_label } = props;
    return(
        <a href="/#/analitica" className="bottom-header-link">
            <div className="info_banner-info--item">
            
                <span className="info_banner-info--title">{titul}</span>
                <div className="info_banner-info--body">
                    <span className="info_banner-info--num">{value}</span>
                    <span className="info_banner-info--plus">+{+diff && <CounterAnimated number={+diff} /> || diff}</span>
                    <span className="info_banner-info--last">за останній місяць</span>
                </div>
        
            </div>
        </a>
    )
}