import React, { useState } from "react";
import ReactDOM from "react-dom";
import Chart from "react-google-charts";

import "./chart.css";

import translate from '../../data/translate.json';



const pieOptions = {
  title: "1",
  pieHole: 0.6,
  slices: [
    {
      color: "#A1B9CA"
    },
    {
      color: "#A1CAC5"
    },
    {
      color: "#B3CAA1"
    },
    {
      color: "#F9E1BF"
    },
    {
      color: "#F39B9B"
    },
    {
      color: "#CAA1C8"
    }
  ],
  legend: {
    position: "right",
    alignment: "start",
    textStyle: {
      color: "233238",
      fontSize: 17
    },
    maxLines: 5
  },
  tooltip: {
    showColorCode: true,
    text:'percentage'
  },
  chartArea: {
    left: 0,
    top: 0,
    width: "100%",
    height: "80%"
  },
  fontName: "Ubuntu, sans-serif"
};

const translText = (text) =>{ 
  Object.keys(translate).forEach(tr=>{ text = text.replace(tr,translate[tr]) });
  return text;
};

const norm_text = (txt) => translText(txt.length > 0 ? `${txt[0].toUpperCase() + txt.slice(1).toLowerCase()}` : "" );


export function  PieChartDetail(props) {

    const {  analitica, count } = props;

    if (typeof analitica == "undefned") return null;

    if(!analitica) return null;

    const blocks = Object.keys(analitica);

    if(blocks.length < 1) return null;
    
    const name_val = (text, val)=> norm_text(text) + " (" + val + ")";

    return(<div>

        <div className="title_chart_result">Надійшло повідомлень {count}</div>
        
        {count > 0 && blocks.map((block, index)=>{

            let all_get = 0;

            const sectors = [["Тип", "Кількість"]];
                        
            Object.keys(analitica[block].data).sort((a,b)=>{
              if(analitica[block].data[b] > analitica[block].data[a]) return 1;
              if(analitica[block].data[b] < analitica[block].data[a]) return -1;
              return 0;
            }).forEach(sect=>{
                all_get += analitica[block].data[sect];
                sectors.push([name_val(sect, analitica[block].data[sect]), analitica[block].data[sect]]);
            });

            return <div className="charts">
            <div className="title_chart_name">
            {index+1}.{' '}{analitica[block].name}</div>
            <Chart
              chartType="PieChart"
              data={sectors}
              options={pieOptions}
              graph_id={"PieChart" + analitica[block].name}
              width={"100%"}
              height={"400px"}
              legend_toggle
            /></div>
        })}
        </div>
    )
}