const get_menu_info = async (func) => {
    const url='/data/nazk_sites.json';

    try{
        const f = await fetch(url, { mode: 'no-cors', headers: { 'Content-Type': 'Application/json'}});
        if(!f.ok) throw new Error("Response not ok");
        const data =  await f.json();
        func(data);
    } catch(error) {
        console.log(`Error with fetch ${error.message}`);
    }


}

export {get_menu_info};