const NOT_SELECT = "Оберіть принаймні одну статтю";
const FRW_SELECT = "Не обрано тип правопопорушення";
const CH_SELECT = "Уточніть тип правопорушення";

const MINIMAL_LENGTH = 5;

export function Validate4(formData, errors){

    const { SelectType, Corruption, Position, Other, Criminal } = formData;

     
    if(!SelectType) {
        errors['SelectType'].addError(FRW_SELECT);
        return errors;
    }

    if( SelectType == "Corruption") {

        const sel_cor = Corruption.SelectType;
        
        if(!sel_cor) {
            errors['Corruption'].addError(CH_SELECT);
            return errors;
        }
        

        const arr = Corruption[sel_cor];

        const pos = Corruption.Position;
        
        if(!arr || arr.length < MINIMAL_LENGTH ) {
            errors['Corruption'][sel_cor].addError(NOT_SELECT);
            return errors;
        }

        if(!pos && ( sel_cor == "Corruption000" ) ) errors['Corruption']['Position'].addError("Оберіть позицію порушника");

    }

    if( SelectType == "Other") {
        if(!Other || Other.length < MINIMAL_LENGTH) errors['Other'].addError(NOT_SELECT);
    }

    if( SelectType == "Criminal") {
        if(!Criminal || Criminal.length < MINIMAL_LENGTH) errors['Criminal'].addError(NOT_SELECT);
    }

    return errors;


}