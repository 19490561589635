import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useHistory } from 'react-router-dom';


import { makeStyles, withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';

import schema_1 from './step1/schema.json';
import ui_schema_1 from './step1/uiSchema.json';
import { Validate1 } from './step1/validate';


import schema_2 from './step2/schema.json';
import ui_schema_2 from './step2/uiSchema.json';
import Label2 from './step2/info_label.js';

import schema_3 from './step3/schema.json';
import ui_schema_3 from './step3/uiSchema.json';

import { Validate3 } from './step3/validate';

import schema_4 from './step4/schema.json';
import ui_schema_4 from './step4/uiSchema.json';

import { Validate4 } from './step4/validate';


import schema_5 from './step5/schema.json';
import ui_schema_5 from './step5/uiSchema.json';
import Label5 from './step5/info_label.js';

import { Validate5 } from './step5/validate';

import schema_6 from './step6/schema.json';
import ui_schema_6 from './step6/uiSchema.json';

import { Validate6 } from './step6/validate6';

import schema_7 from './step7/schema.json';
import ui_schema_7 from './step7/uiSchema.json';

import schema_email from './email/schema.json';
import ui_schema_email from './email/uiSchema.json';


import { StepperV } from './stepper';

import FinishAnketa from './finish';


import Grid from '@material-ui/core/Grid';

import { HelpInfoAnketa } from './components/helpinfo';
import { FormAnketa } from './components/formAnketa';


import './style.css';

const MUST_ORG_NAZK = 'Національне агентство з питань запобігання корупції';

const sourceOfInformationId = {
  "Участю у передбачених законодавством процедурах, які є обов’язковими для початку такої діяльності, проходження служби чи навчання": "800",
  "Проходженням навчання": "700",
  "Проходженням служби": "600",
  "Науковою діяльністю": "500",
  "Громадською діяльністю": "400",
  "Господарською діяльністю": "300",
  "Професійною діяльністю": "200",
  "Трудовою діяльністю": "100"
};

const activityDescriptionId = {
  "діяльність інженера": "210",
  "діяльність оцінщика": "209",
  "діяльність бухгалтера": "208",
  "діяльність аудитора": "207",
  "служба в органах влади Автономної Республіки Крим, органах місцевого самоврядування": "609",
  "діяльність арбітражного керуючого (розпорядника майна, керуючого санацією, ліквідатора)": "206",
  "патронатна служба в державних органах": "608",
  "діяльність адвоката": "205",
  "інша державна служба": "607",
  "діяльність приватного виконавця": "204",
  "альтернативна (невійськова) служба": "606",
  "діяльність приватного нотаріуса": "203",
  "діяльність лікаря": "202",
  "участь фізичної особи у літературній, артистичній, художній, освітній або викладацькій діяльності": "201",
  "військова служба": "605",
  "професійна діяльність прокурора": "604",
  "професійна діяльність судді": "603",
  "діяльність у державному колегіальному органі": "602",
  "діяльність на державній політичній посаді": "601",
  "стажування": "801",
  "інше": "805",
  "вступ до навчального закладу": "804",
  "зайняття іншою подібною діяльністю за умови, що така особа не є працівником або фізичною особою – підприємцем та використовує найману працю не більш як чотирьох фізичних осіб": "213",
  "обрання до членів громадської ради": "803",
  "діяльність особи, зайнятої релігійною (місіонерською) діяльністю": "212",
  "діяльність архітектора": "211",
  "участь в конкурсі на зайняття посади/прийняття/відбір на роботу/службу": "802"
};


const req_text = 'Поля, що мають бути заповнені обов\'язково, відмічені символом *';

let steps = [
    "Крок 1",
    "Крок 2",
    "Крок 3",
    "Крок 4",
    "Крок 5"
];

let schemas = [
    schema_1, schema_3, schema_5, schema_4,  schema_6
];

const validate = [Validate1, Validate3, Validate5, Validate4,  Validate6];

let ui_schemas = [
    ui_schema_1, ui_schema_3, ui_schema_5, ui_schema_4, ui_schema_6
];

const labels = [
    null,null,<Label5 />,null,null
];


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form:{
      maxWidth: '96%',
      margin: 'auto'
  },
  body:{
      maxWidth: '1280px',
      minHeight: '600px',
      margin: 'auto'
  },
  next_button:{
      backgroundColor: '#FCDA4B'
  },
  help_info_main:{
    backgroundColor: '#E8E8E8',
    marginTop: '80px',
    marginLeft: '70px',
    display: 'none',
    [theme.breakpoints.up('md')]:{
      display: 'table'
    }
  },
  help_info_text:{
//    display: 'table-cell',
    verticalAlign: 'middle',
    padding: '20px 30px 20px 20px',
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px',
    '& a':{
      color: '#1c4860',
      fontWeight: '400',
      textDecoration: 'none'
    }
  },
  form_block:{
    marginTop: '10px'
  },
  req_text:{
      padding: '30px 0px',
      'font-size': '12px'
  }
}));

const theme = createTheme({
  palette: {
    primary: { 
      main: '#007cba',
      checked: 'blue'
     }, // Purple and green play nicely together.
    secondary: { main: '#11cb5f' }, // This is just green.A700 as hex.
  }
});



const constructOutData = (anketa, organizations)=>{

  const { Organization, Territorial } = anketa;

  const { value, name } = JSON.parse(Organization);
  
  const [anketa_name] = value.split('#');

  const organ = organizations.find(el=>el.name == anketa_name);

  const Territorial_organ = Territorial && organizations.find(el=>el.name == Territorial);

  

  const pair_tr = [
    ["userName","Name"],
    ["phone", "Phone"],
    ["email", "Email"],
    ["address", "Address"],
    ["recordIdDepartment", "recordIdOrganization"],
    ["againstWhomMessage", "PersonaType"],
    ["whomSendMessage", "ReciverPerson"],
    ["whoCommittedTheOffense", "Position"],
//    ["selectTheAuthorityToSendMessage", "selectTheAuthorityToSendMessage"],
    ["place", "place"],
    ["date_ev", "date_ev"],
    ["sum_bride", "sum_bride"],
    ["sum_dem", "sum_dem"],
    ["person", "person"]
  ];

  const out = {
    "workflowTemplateId": "161102",
    "taskTemplateId": "161102005",
    "initData": {
    }
  };

  const { initData } = out;

  initData['edrpouDepartment'] = Territorial_organ ? Territorial_organ.edrpou: organ.edrpou;
  initData['recordIdDepartment'] = Territorial_organ ? Territorial_organ.id : organ.id;

  initData["nameDepartment"] = Territorial_organ ? Territorial_organ.name : organ.name;

  if(Territorial_organ) initData["isTerritorialAuthorities"] = true;

  if(Territorial_organ) initData["edrpouCentralAuthorities"] = organ.edrpou;
  if(Territorial_organ) initData["recordIdCentralAuthorities"] = organ.id;


  const offence_types = {
    Name: [...schema_4.dependencies.SelectType.oneOf[0].properties.Criminal.enum1, ...schema_4.dependencies.SelectType.oneOf[1].properties.Corruption.dependencies.SelectType.oneOf[0].properties.Corruption000.enum1 , ...schema_4.dependencies.SelectType.oneOf[1].properties.Corruption.dependencies.SelectType.oneOf[1].properties.Corruption188.enum1,  ...schema_4.dependencies.SelectType.oneOf[2].properties.Other.enum1],
    Index: [...schema_4.dependencies.SelectType.oneOf[0].properties.Criminal.enum2, ...schema_4.dependencies.SelectType.oneOf[1].properties.Corruption.dependencies.SelectType.oneOf[0].properties.Corruption000.enum2, ...schema_4.dependencies.SelectType.oneOf[1].properties.Corruption.dependencies.SelectType.oneOf[1].properties.Corruption188.enum2, ...schema_4.dependencies.SelectType.oneOf[2].properties.Other.enum2]
  };

  if(anketa.Corruption && anketa.Corruption.SelectType) {
    const typeCor = anketa.Corruption.SelectType;
    const cor_arr = anketa.Corruption[typeCor];
    const pos = anketa.Corruption.Position;
    const toMsg = anketa.Corruption.selectTheAuthorityToSendMessage || '';
    anketa.Corruption = cor_arr;
    anketa.Position = pos;
    anketa.selectTheAuthorityToSendMessage = toMsg;
  }

  
  const offences = [...JSON.parse(anketa.Criminal || "[]"), ...JSON.parse(anketa.Corruption || "[]"), ...JSON.parse(anketa.Corruption188 || "[]"), ...JSON.parse(anketa.Other || "[]") ];

  if(anketa && anketa.Criminal) initData['criminal'] = JSON.parse(anketa.Criminal);
  
  if(anketa && anketa.Corruption) {
    initData['corruption'] = JSON.parse(anketa.Corruption);
    //if(anketa && anketa.Position && anketa.Position === 'інший суб’єкт') initData['selectTheAuthorityToSendMessage'] = organ;
//    if(anketa && anketa.Position && anketa.Position !== 'інший суб’єкт') {
//      initData['selectTheAuthorityToSendMessage'] = 'Національне агентство з питань запобігання корупції';
//    } else {
//      initData['selectTheAuthorityToSendMessage'] = 'Національна поліція';
//    }
  }

  if(anketa && anketa.Corruption188) {
    initData['corruption'] = [JSON.parse(anketa.Corruption188)["0"]];
  }

 
  //if(anketa['selectTheAuthorityToSendMessage'] && anketa['selectTheAuthorityToSendMessage'].match(/запобігання/)) {
    initData['selectTheAuthorityToSendMessage'] = anketa['selectTheAuthorityToSendMessage'] == MUST_ORG_NAZK  ? 
      "1" : anketa['selectTheAuthorityToSendMessage'] == organ.name ? 
      "0" : anketa.Position == "інший суб’єкт" && anketa.Corruption.length > 5 ? "0" : "";

 // } else {
 //   initData['selectTheAuthorityToSendMessage'] = "0";
//  }

  if(anketa && anketa.Other) initData['otherOffenses'] = JSON.parse(anketa.Other);

  initData['sourceOfInformation'] =  [anketa.Source];

  initData['sourceOfInformationId'] = sourceOfInformationId[anketa.Source];

  if(anketa.Prof) initData['sourceOfOffenseOther'] = anketa.Prof;
  if(anketa.Sluzh) initData['sourceOfOffenseOther'] = anketa.Sluzh;
  if(anketa.Procedurs) initData['sourceOfOffenseOther'] = anketa.Procedurs;

  if(initData['sourceOfOffenseOther']) initData['activityDescriptionId'] = activityDescriptionId[initData['sourceOfOffenseOther']];

  const offenc_t = [
    ...JSON.parse(anketa.Criminal || "[]").map(el=>({ name: 'Кримінальні корупційні правопорушення'})),
    ...JSON.parse(anketa.Corruption || "[]").map(el=>({ name: 'Правопорушення, пов’язані з корупцією'})),
    ...JSON.parse(anketa.Corruption188 || "[]").map(el=>({ name: 'Правопорушення, пов’язані з корупцією'})),  
    ...JSON.parse(anketa.Other || "[]").map(el=>({ name: 'Інші порушення Закону України «Про запобігання корупції»'})), 
  ];

  initData['offense'] = offenc_t;

  const numberERDR = offences.map(el=>({
      "codeERDR": offence_types.Index[offence_types.Name.findIndex(i=>i==el)],
      "nameOffense":el 
    })
  );

  const files = (anketa.Materials || []).map(el=> {
    const parts = el.split(";");
    const name = parts.find(el=>el.match(/name=/)).substr('name='.length);
    const type = parts.find(el=>el.match(/data:/)).substr('data:'.length);
    const c_length = parts.find(el=>el.match(/size=/)).substr('size='.length);
    const content = parts.find(el=>el.match(/base64,/)).substr('base64,'.length);

    return ({
      name: name,
      contentType: type,
      contentLength: c_length,
      fileContent: content
    })
  });

  initData['numberERDR'] = numberERDR;
  initData['files'] = files;

  initData['text'] = anketa.Message.replace(/<[^>]+>/g, '');

  pair_tr.forEach(pair=>{
    if(anketa.hasOwnProperty(pair[1])) initData[pair[0]] = anketa[pair[1]]
  });


          return out;

}



export function TestStepper(props){

    const { setVisibleLabel } = props;

    const classes = useStyles();

    const history = useHistory();

    const [activeStep, setActiveStep ] = useState(0);

    const [formData, setFormData] = useState([]);

    const [anketa_data, setData] = useState({});

    const [organList, setOrgList] = useState([]);

    const [result_post, setPostResult] = useState({ state: 'unuse', result: {} });

    const [u_num, setUNum] = useState({ num: '', date: new Date()});

    const [key_step, setKeyStep] = useState('');

    const [isEmail, setIsEmail] = useState('');

    const [isError, setError] = useState(false);

    const [urlDoc, setUrlDoc] = useState('');

    const[errorForm, setErrorForm] = useState([]);

    const[stepNext, setNextStep] = useState(-1);

    const [updClick, setUpdClick] = useState(false);

    const [dependData, setChangeData] = useState({});

    const addInfoRef = useRef();

    const dataOrganList = async ()=> {
        const url = '/api/organization';
        const f = await fetch(url);
        const data =  await f.json();

        const central = data.data.filter(row=>!row.isTerritorialAuthorities);

        const territorial = {};

        data.data.filter(row=>(row.isTerritorialAuthorities && row.edrpouCentralAuthorities) ).forEach(row=>{
          
          if(!territorial[row.edrpouCentralAuthorities]) territorial[row.edrpouCentralAuthorities] = [];

          territorial[row.edrpouCentralAuthorities].push(row);

        });


        schemas[0].properties.Organization['enumName']=central.map(row=>`${row.name}`);
        schemas[0].properties.Organization['enumt']=central.map(row=>`${row.name}#${row.edrpou}`);
        //schemas[0].properties.Organization['enum']=central.map(row=>`${row.name}#${row.edrpou}`);


        Object.keys(territorial).forEach(key=>{


          const head = central.find(row=>row.edrpou == key);

          if(!head) return;
/*
          let indexOneOf = schemas[0].dependencies.Organization.OneOf.findIndex(row=>
            row && row.properties && row.properties.Terrritorial 
              && row.properties.Terrritorial.enum && row.properties.Terrritorial.enum.includes(head.name)
            );
          
          if(indexOneOf == -1) {

            schemas[0].dependencies.Organization.OneOf.push(

            )

          };
*/

          const elem_ter = {
            properties: {
              Organization: {
              enum: [
//                  `${head.name}#${head.edrpou}`
                    JSON.stringify({ value: `${head.name}#${head.edrpou}`, name: `${head.name}`})
              ]
              },
              Territorial:{
                  title:"Територіальний орган",
                  type: "string",
                  enum:[...territorial[key].map(row=>row.name)],
                  default: territorial[key][0].name
              }
            }
          }

        schemas[0].dependencies.Organization.oneOf.push(elem_ter);

        })

 

        if(!data.data || data.data.length < 1 ) return setError(true);
        setOrgList(data.data);
  
        
      }


    const postAnketa = async (anketa)=> {
      if(result_post.state != 'unuse') return null;
      setPostResult(Object.assign({},result_post, { state: "in_progres"}));
      const url = '/api/anketa';
      const options = {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer',
        timeout: 600, 
        body: JSON.stringify(anketa)
      };

      try {
        const f = await fetch(url, options);

        if(!f.ok) throw new Error(`Network status problem ${f.status}`);   
          
        const data =  await f.json();
        setUNum({ num: data.data.info.messageNumber, date: new Date(data.data.date), file: data.data.file });
        setPostResult({ state: "complited", result: data.data });
        //getAddInfo(data.data.info.messageNumber);

      } catch(error){
        console.log('url error', error);
        setPostResult({ state: "error" });
      }
    }
    
    const getAddInfo = async (identificator)=> {

      const url = `/api/anketa/${identificator}`;
      const f = await fetch(url);

      if(f.ok) {
          const data =  await f.json();
          if(data && data.data && data.data.length == 0) return null;
          setUrlDoc(data.data.PDFfileStatus.url);
      } else {
          alert('Вибачте, виникла помилка')
      }
  
  }

    useEffect(()=>{
        if(organList.length > 0) return;
        if(isError) return;
        dataOrganList();
    });

    const go_head = ()=> 
    { 
      history.push('/');
    }

    useEffect(()=>{
      if( false && (activeStep == steps.length) && !anketa_data.form ) {
        alert('Повідомлення відправлено. Очікуйте відповідь на вказану електрону пошту');
        go_head();
        return;
      }
      if( activeStep == steps.length) postAnketa(constructOutData(anketa_data, organList));
    },[activeStep]);

    const handleReset = ()=>{
        setActiveStep(0);
        setVisibleLabel(true);
    }

    const getShift = (data) => (activeStep == 0) && !data.typemessages ? 1 : 1;

    const checkData = (data)=>{
      if(activeStep === 3) {

        const types = ['Criminal', 'Corruption', 'Corruption188', 'Other'];

        const intersection = types.filter(x=>data[x] && data[x] != '[]');

        if(intersection.length > 1) return false;
      }

      return true;
    }
    
  

    const onSubmit = (e)=>{
        
      const data = e.formData;

      const step = e.directStep == 'forward' ? 1:-1;

        if(activeStep == 3) {

          if( ( data.SelectType == 'Corruption') && data.Corruption && data.Corruption.SelectType) {
            data['Criminal'] = "[]";
            data['Other'] = "[]";
          }
  
          const arTypes = ['Criminal', 'Other'];
  
          if( data.SelectType && arTypes.includes(data.SelectType) ) data.Corruption = "[]";
  
          arTypes.forEach(el=>{
            if(el !== data.SelectType) data[el] = "[]";
          })
        }
  
  
        if(!checkData(data)) return alert('Будь ласка, оберіть один вид правопорушення');
  
        setData(Object.assign({}, anketa_data, data));
        const newFormData = [...formData];
        newFormData[activeStep] = data;
        upd_data_schemas(activeStep, data);
        setFormData(newFormData);        
        //handleMail(e.formData);
        setActiveStep(activeStep + step);
        
    }

    const upd_data_schemas = (step, data)=>{

      //const MUST_ORG_POLICE = 'Національна поліція';

      const corupt000 = schemas[3].dependencies.SelectType.oneOf[1].properties.Corruption.dependencies.SelectType.oneOf[0].dependencies.Corruption000;
      const corupt188 = schemas[3].dependencies.SelectType.oneOf[1].properties.Corruption.dependencies.SelectType.oneOf[1].properties.selectTheAuthorityToSendMessage;

      const corupt000_pos = corupt000.oneOf[0].dependencies.Position.oneOf[1].properties.selectTheAuthorityToSendMessage;
      //const corupt000_pos1 = corupt000.oneOf[0].dependencies.Position.oneOf[1].properties.selectTheAuthorityToSendMessage;
 
  //    return;
      if(step == 0) {

        const centr_org = data.Organization && JSON.parse(data.Organization)['value'].split('#')[0].trim();
        const territ_org =  data.Territorial;

        const nameOrg = `${centr_org}${territ_org && ('. '+territ_org) || ''}`;

        let new_data= [nameOrg];
        let new_data_indx = [0];

      if(nameOrg != MUST_ORG_NAZK) {
        new_data[1] = MUST_ORG_NAZK;
        new_data_indx[1] = 1;
      };

      //corupt000_pos0.enumNames = JSON.parse(JSON.stringify([nameOrg,""]));
      //corupt000_pos0.enum = JSON.parse(JSON.stringify([0,0]));
      corupt000_pos.enumNames = JSON.parse(JSON.stringify(new_data));
      corupt000_pos.enum = JSON.parse(JSON.stringify(new_data));
      corupt000_pos.default = new_data[0];
      corupt188.enumNames = JSON.parse(JSON.stringify(new_data));
      corupt188.enum = JSON.parse(JSON.stringify(new_data));

/*        

        corupt000_pos0.enumNames[0] = nameOrg;
        corupt000_pos1.enumNames[0] = nameOrg;
        corupt188.enumNames[0] = nameOrg;
        
        const length_names_array = corupt000_pos1.enumNames.length;
        
        if(nameOrg == 'Національне агентство з питань запобігання корупції' && length_names_array > 1 ) {
          corupt000_pos1.enumNames.pop();
          corupt000_pos1.enum.pop();
          corupt188.enumNames.pop();
        } 
                        
        if( !(nameOrg == 'Національне агентство з питань запобігання корупції') && length_names_array < 2) {
          corupt000_pos1.enumNames.push('Національне агентство з питань запобігання корупції');
          corupt000_pos1.enum.push('Національне агентство з питань запобігання корупції');
          corupt188.enumNames.push('Національне агентство з питань запобігання корупції');
        }


        if(nameOrg.match(/аціональна поліція/) ) {
          corupt000_pos1.enumNames.pop();
          corupt000_pos1.enum.pop();
          corupt188.enumNames.pop();
        } else if(length_names_array < 2) {
          corupt000_pos1.enum.push('Національна поліція')
          corupt188.enum.push('Національна поліція')
        }

*/
        
      }
    }

    const handleMail = (data)=>{

      if(activeStep != 1 ) return;

      
      schemas = !data.form ? [schema_1, schema_2, schema_email] : [schema_1, schema_2, schema_3, schema_4, schema_5, schema_6];
      ui_schemas = !data.form ? [ui_schema_1, ui_schema_2, ui_schema_email] : [ui_schema_1, ui_schema_2, ui_schema_3, ui_schema_4, ui_schema_5, ui_schema_6];

      steps = data.form ?  [
          "Крок 1",
          "Крок 2",
          "Крок 3",
          "Крок 4",
          "Крок 5",
          "Крок 6",
      ] : [ "Крок 1",
          "Крок 2",
          "Крок 3",];

    }

    const handleChange = (data)=>{
      setChangeData(data);
    }

    if(activeStep == steps.length) setVisibleLabel(false);


    if(activeStep == steps.length) return(
        <div><FinishAnketa state={result_post.state} uniq_number={u_num.num}  date_reg={u_num.date} file={u_num.file}/></div>
    );

    if(isError) return <div>Виникла помилка в роботі. Спробуйте пізнійше</div>;

    return(
        <div>
        <StepperV propsActiveStep={ activeStep } propsSteps={steps} />
        <div className={classes.form_block}/>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
        { !isError && <div className={classes.form}>
        { ( organList.length > 0 ) && 
        
        <ThemeProvider theme={theme}>
          <FormAnketa 
            key_step={new Date()} 
            formData={Object.keys(dependData).length && dependData || formData[activeStep]} 
            schema={schemas[activeStep]}
            uiSchema={ui_schemas[activeStep]}
            onSubmit={onSubmit}
            validate={validate[activeStep]}
            isFinish={activeStep === (steps.length -1)}
            setTextAddInfo={addInfoRef.current.setText}
            classes={classes}
            handleReset={handleReset}
            label={labels[activeStep]}
            isBegin={activeStep === 0}
            onChangeForm={handleChange}
            />
            <div className={classes.req_text}>{req_text}</div>
        </ThemeProvider>
         }
        </div> }
        </Grid>
        <HelpInfoAnketa 
          isShow = {ui_schemas[activeStep].HelpInfo} 
          mainText = { ui_schemas[activeStep].HelpInfo && ui_schemas[activeStep].HelpInfo.main_info }
          dependText = {ui_schemas[activeStep].HelpInfo && ui_schemas[activeStep].HelpInfo.depend_info || null}
          dependKey = {ui_schemas[activeStep].HelpInfo && ui_schemas[activeStep].HelpInfo.depend_key || null}
          //addText = {text_add_info}
          formData={dependData}
          classes={classes} 
          ref={addInfoRef}
        />
        </Grid>
        </div>
        
    )
}