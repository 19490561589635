export function Validate5(formData, errors){

    const keys = Object.keys(formData).filter(key=> (key != 'Source') && (key != 'TitleSource') );


    const res = keys.reduce((acc,cur)=>acc+ ( ( formData[cur] && formData[cur] != "[]" ) ? 1 : 0), 0);

    if(keys.length > 0 && res < 1) {
        
//            errors['Source'].addError("Оберіть принаймні один вид діяльності");
    
    }

    return errors;


}