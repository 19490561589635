import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }));



export function AutoCompliteComboBox1(props) {

    const classes = useStyles();

    const { options, optionsName, onChange, value, label} = props;

    const [val_1, setValue] = useState(options[0]);

    useEffect(()=>{
      if(!!value) setValue(value);
    }, value);

    const v_t = options.filter(el=>el.enum == val_1);
    
    const v_1 = v_t && v_t[0];

    const opttest = ['test1', 'test2', 'test3'];

  return (
    <Autocomplete
    options={options}
      getOptionLabel={(option)=> option.enumName}
      value={v_1} 
      onChange={onChange}

    renderInput={(params) => <TextField {...params} label={label} multiline   /> }
    />
  );
}