import React, {Component} from 'react';
import SVG from 'svg.js';



const style = {
	width: '1200px',
	display: 'block',
	'text-align': 'center'
} 
	

export default class UkraineMap extends Component {
    onSelect(region){
        let {value} = this.props;
        if (this.props.multiple) {
            const index = this.props.value.indexOf(region);
            index >= 0 ? value.splice(index, 1) : value.push(region);
        } else {
            value = region;
        }

        this.props.onSelect && this.props.onSelect(null, {value});
    }

    componentDidMount() {
        let draw = SVG(this.refs.map).size(620, 410);
        let pathes = {};
        for (let region of this.props.regions) {
            let path = draw.path(region.d);
            path.fill(this.pathColor(region.title)).stroke({color: '#CACBCD', width: 1, linecap: 'round', linejoin: 'round'});

            if (this.props.selectable) {
                path.on('mouseover', () => {
                    if (this.props.value.indexOf(region.title) < 0) {
                        path.fill('#CACBCD');
                    }
                    this.showPopup(path, region.title);
                });
                path.on('mouseout', () => {
                    path.fill(this.pathColor(region.title));
                    this.hidePopup();
                });
                path.on('click', () => {
                    this.onSelect(region.title);
                });
            }

            pathes[region.title] = path;
        }
        this.setState({pathes});
        document.body.appendChild(this.refs.popup);
    }

    componentDidUpdate() {
        for (let pathId in this.state.pathes) {
            this.state.pathes[pathId].fill(this.pathColor(pathId));
        }
    }

    componentWillUnmount() {
        this.refs.popup.remove();
    }

    showPopup(path, hint) {
        const {top, left, width} = path.node.getBoundingClientRect();
        const {popup} = this.refs;
        const content = popup.getElementsByClassName('content')[0];
        content.innerText = hint;

        popup.style.top = 1890 + (top - 52) + 'px';
        popup.style.left = (left - 124 + width / 2) + 'px';
        popup.classList.add('visible');
    }

    hidePopup() {
        const {popup} = this.refs;
        popup.classList.remove('visible');
    }

    pathColor(pathId) {
        let selected = false;
        if (this.props.multiple) {
            selected = this.props.value.indexOf(pathId) >= 0;
        } else {
            selected = this.props.value === pathId;
        }

        return selected ? '#4183C4' : '#F3F4F5';
    }

    render() {
        return (
            <segment style={style}>
                <svg ref="map"/>
                <div ref="popup" className="ui top center popup transition" style={{position: 'absolute'}}>
                    <div className="content">test</div>
                </div>
            </segment>
        );
    }
}
