import React, { useState } from 'react';

import './style.css';

export function Banner(){
    return (
        <div className="faq-banner-body">

        <div className="faq-banner-main">

        <div className="faq-banner-main--head">
        Не отримали відповіді на своє питання?
        </div>

        <div className="faq-banner-main--text">

        База знань НАЗК містить роз’яснення та відповіді на питання за такими розділами:
        <ul>
            <li>Викривачі корупції;</li>
            <li>Реєстр корупціонерів;</li>
            <li>Антикорупційні уповноважені;</li>
            <li>Запобігання політичній корупції;</li>
            <li>Конфлікт інтересів;</li>
            <li>Декларування.</li>
        </ul>
                
        </div>

        <div className="faq-banner-main--button">
                <a href="https://wiki.nazk.gov.ua/" target="_blank">До бази знань НАЗК</a>
        </div>
                
        </div>

        </div>
    )
}