function extractContent(html) {

    const repl = ['\ufeff'];

    let plain = new DOMParser()
        .parseFromString(html, "text/html")
        .documentElement.textContent;

    repl.forEach(r=>{
        plain = plain.replace(r,"");
    });

    return plain;
}


export function Validate6(formData, errors){

    const { Message } = formData;

    if(!Message) return errors;

    if( typeof Message == "undefined") errors['Message'].addError("Обов`язкове поле");

    const text_unf = Message && extractContent(Message) || null;

    if(!text_unf) errors['Message'].addError("Обов`язкове поле");

    return errors;

}