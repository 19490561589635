const statInfo = async (func, params)=> {

    const url = `api/statistics`;

    const p_urls = Object.keys(params).map(el=>`${el}=${params[el]}`).join('&');
    const f = await fetch([url, p_urls].join('?'));

    if(f.ok) {
        const data =  await f.json();
        
        func(data.data);
    } else {
        alert('Вибачте, виникла помилка при отриманні статистики');
        func({});
    }

};

export { statInfo };