import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Header } from '../components/header';

//import { Body } from '../components/body';
import { Footer } from '../components/footer';

import { Footer1 } from '../components/footer1';

import { MainBreadcrumbs } from '../components/breadcrumb';

import { Typography } from '@material-ui/core';

import { FindResult } from '../components/findresult';

import { NotesInfo } from '../components/notes';
import { MovMsgs } from '../components/mov_msgs';
import { FAQ } from '../components/faq';

import { OrganInfo } from '../components/organization';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  body:{
      maxWidth: '1280px',
      minHeight: '600px',
      margin: 'auto'
  }
}));

const path_br = [
   
];

const cur_br = { title: "Головна", link: "" }

export function HeadPage(props) {

    const classes = useStyles();

    return (
        <div >
            <Header short={false} />
            <div  className={classes.body}>
                <FindResult  className={classes.body}/>
                <NotesInfo />
                </div>
                <div>
                <MovMsgs />
                {false && <OrganInfo />}
                <FAQ />
            </div>
            <Footer1 />
        </div>
    )
}

function LabelBlock(){
    return(
        <div>
        <Typography variant="h3" component="h4" gutterBottom>Повідомлення про корупцію</Typography>
        </div>
    )
}