import React, { useEffect, useState } from 'react';

export function CounterAnimated(props) {

    const requestRef = React.useRef();
    const previousTimeRef = React.useRef();
    
    const [ count, setCount ] = useState(0);

    const { number } = props;

    const duration = 3000, start = 0;

    const result_format = (num) => new Intl.NumberFormat('uk-Uk',{ maximumFractionDigits: 2}).format(num);
    
    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animateValue);
        return () => cancelAnimationFrame(requestRef.current);
      }, []);


      const animate = time => {
        if (previousTimeRef.current != undefined) {
          const deltaTime = time - previousTimeRef.current;
          
          // Pass on a function to the setter of the state
          // to make sure we always have the latest state
          setCount(prevCount => (prevCount + deltaTime * 0.01) % 100);
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
      }
    
    const animateValue = () => {
        let startTimestamp = null;
        const step = (timestamp) => {
          if (!startTimestamp) startTimestamp = timestamp;
          const progress = Math.min((timestamp - startTimestamp) / duration, 1);
          const newCount = Math.floor(progress * (number - start) + start);
          setCount( result_format( number - newCount < 1 ? number : newCount ) );
          if (progress < 1) {
            requestRef.current = requestAnimationFrame(step);
          }
        };
        requestRef.current = requestAnimationFrame( step );
      };


    return <span>{count}</span>
}