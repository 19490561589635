import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }));



export function AutoCompliteComboBox(props) {

    const classes = useStyles();

    const { options, optionsName, onChange, value, label} = props;

    const opt = optionsName ? options.map( (value, index) => ({ value, name: optionsName[index] }) ) : options;


  return (
    <Autocomplete
      options={optionsName ? opt : options}
      getOptionLabel={(option) => optionsName ? option.name : option}
      value={optionsName ? value && JSON.parse(value) : value} 
      onChange={onChange}
      renderInput={(params) => <TextField {...params} label={label} multiline   />}
    />
  );
}