import { makeStyles } from '@material-ui/core/styles';

import alert_ico from './img/alert.svg';

const useStyles = makeStyles((theme) => ({
  label: {
//      position: 'absolute',
      width: '544px',
//      height: '80px',
      left: '80px',
      top: '515px',
      background: '#FFF4D7',
      borderRadius: '2px',
      marginTop: '20px',
      marginBottom: '20px',
    '&::before':{
      content: `url(${alert_ico})`,
      position: 'absolute',
      margin: '2.9em 1.5em'
    }
  },
  m_block: {
    padding: '14px 14px 14px 58px'
  },
  title: {
    marginBottom: '5px',
    fontWeight: '700'
  },
  phone: {
    color: '#007CBA'
  },
  text:{
      padding: '5px',
      fontFamily: 'Ubuntu',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      marginRight: '80px'
  }
}));



export default function Label1Info(){
    const classes = useStyles();

    return(
        <div className={classes.label}>
           <div className={classes.m_block}>
            <p className={classes.title}>Ви також можете повідомити про корупцію</p>
            <p>за телефоном «спеціальної гарячої лінії» <span className={classes.phone}>+38(044) 200-06-91</span> </p>
            </div>
        </div>
    )
}


