import React from 'react';

import { Facebook, YouTube, Twitter, Telegram } from '@material-ui/icons';


//import './style.css';

import Herb from './img/herb.svg';

export function Footer1(){
    return(
        <footer className="footer">
            <div className="footer-head">
                {false && <div className="container">
                    <button className="btn btn-map">Мапа сайту<i className="ico-arrows1"></i></button>
                </div>}
            </div>
            <div className="container">
                <div className="footer-body">

                    <div className="footer-mcont">
                        <div className="footer-litem">
                            <span className="footer-litem--title">Адреса</span>
                            <p className="footer-litem--text">
                                Україна,  01103 <br/>
                                м. Київ,  бульвар Миколи Міхновського, 28
                            </p>
                        </div>
                    </div>

                    <div className="footer-mcont">
                        <div className="footer-center">
                            <div className="footer-center--logo">
                                <img src={Herb} alt="Ukraine"/>
                            </div>
                            <span className="footer-center--text">
                                Єдиний портал <br/>
                                повідомлень викривачів
                            </span>
                        </div>
                    </div>

                    <div className="footer-mcont">
                        <div className="footer-litem">
                            <span className="footer-litem--title">Електронна адреса</span>
                            <a className="footer-litem--text">info@nazk.gov.ua</a>
                        </div>
                        <div className="footer-litem">
                            <span className="footer-litem--title">НАЗК в соцмережах:</span>
                            <div className="footer-litem--socials">
                                <a href="https://www.facebook.com/NAZKgov" className="ico ico-social ico-facebook"><Facebook/></a>
                                <a href="https://www.youtube.com/channel/UCKwoUDbscWm4BT7BoBo0kMg/" className="ico ico-social ico-youtube"><YouTube /></a>
                                <a href="https://twitter.com/NAZK_gov" className="ico ico-social ico-twitter"><Twitter /></a>
                                <a href="https://t.me/NAZK_gov_ua" className="ico ico-social ico-telegram"><Telegram /></a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="footer-foot">
                    <span className="footer--copyright">
                        Весь контент доступний за ліцензією <br/>
                        <b>Creative Commons Attribution 4.0 International license, якщо не зазначено інше</b>
                    </span>
                </div>
            </div>
        </footer>
    )
}
