import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
//      position: 'absolute',
//      width: '448px',
      height: '182px',
      left: '80px',
      top: '515px',
      background: '#FFF4D7',
      borderRadius: '2px',
      marginTop: '20px',
      marginBottom: '20px'
  },
  text:{
      padding: '20px 20px 10px 40px',
      fontFamily: 'Ubuntu',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '16px',
      marginRight: '38px'
  }
}));



export default function Label5Info(){
    const classes = useStyles();

    return(
        <div className={classes.label}>
            <div className={classes.text}>
            
            <p>
            Особа, яка подає повідомлення, несе персональну відповідальність за достовірність даних про джерело отримання нею інформації.
Звертаємо увагу, що встановлення факту недостовірності таких даних в подальшому тягне за собою деактивацію статусу викривача та неможливість реалізації прав та гарантій захисту викривача.            </p>
        </div>
        </div>
    )
}





/*
Попереджаємо,   що   випадку   надання   недостовірної   інформації   щодо   джерел   отриманняінформації,   визначених Законом України «Про запобігання корупції», Ваш статус викривачабуде деактивовано*/