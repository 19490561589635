import React, { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { Box, Paper, Button, Typography, Grid} from '@material-ui/core';

import logoIcon from '../header/logo.svg';

import { yellow } from '@material-ui/core/colors';

import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Link from '@material-ui/core/Link';

import LinearBuffer from './components/loader';

const useStyles = makeStyles((theme) => ({
  label: {
//      position: 'absolute',
      width: '448px',
      height: '182px',
      left: '80px',
      top: '515px',
      background: '#FFF4D7',
      borderRadius: '2px',
      marginTop: '20px',
      marginBottom: '20px'
  },
  text:{
      padding: '5px',
      fontFamily: 'Ubuntu',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      marginRight: '80px'
  },
  info:{
      textAlign: 'center'
  },
  body:{
      //backgroundColor: '#E5E5E5',
      margin: 'auto'
  },
  logo:{
      margin: 'auto'
  },
  mb:{
      display: 'block',
      padding: '20px'
  },
  card:{
      width: '427px',
      height: '217px',
      left: '439px',
      top: '279px',
      background: '#F2F9FD',
      borderRadius: '4px',
      margin:'auto',
      padding: '10px',
      boxShadow: '0px 2px 8px rgba(92, 99, 105, 0.15)'
  },
  linfo:{
      maxWidth: '375px',
      //height: '52px',
      //left: '517px',
      //top: '374px',
      background: '#FFF4D7',
      borderRadius: '2px',
      margin: 'auto',
      //marginLeft: "10px",
      //textAlign: 'center',
      padding: '5px'
  },
  b_back:{
      marginLeft: '10%',
      marginRight: '50%',
      marginTop: '100px',
      marginBottom: '50px'

  },
  label_load:{
      verticalAlign: 'super',
      marginLeft: '10px'
  },

  linkLoad:{
      textDecoration: 'none',
      color: 'black'
  }

}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    width: '350px',
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: yellow[700],
    },
  },
}))(Button);

const m_ua = ['січня', 'лютого','березня','квітня','травня', 'червня','липня','серпня','вересня','жовтня','листопада','грудня'];


//const curDate = new Date();

const f_h = (d)=>d.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

//const uniq_number = curDate.getTime().toString() + 'K';


export default function FinishAnketa(props){
    const classes = useStyles();

    const [st_load, setLoad] = useState('');

    const [uq_num, setUniqNumber] = useState('');

    const [d_reg, setDateReg] = useState('');

    const { state, uniq_number,  date_reg, file } = props;

    const chLoad = ()=>{
        setLoad(state);
    }
    
    const chNumber = ()=>{
        setUniqNumber(uniq_number);
        setDateReg(new Date(date_reg) );
    }

    useEffect(()=>{
        if(!state) return;
        chLoad();
    }, [state]);

    useEffect(()=>{
        if(!uniq_number) return;
        chNumber();
    }, [uniq_number]);


    const LoadWait = ()=><div>
        <div>Повідомлення завантажується до системи ...</div>
        <LinearBuffer />
    </div>

    const ErrorLoad = ()=><div>
        Виникла помилка під час завантаження. Будь ласка перевірте доступ до Інтернет та спробуйте ще раз.
    </div>

    //  о {f_h(d_reg.getHours()) }:{f_h(d_reg.getMinutes())} //info about time


    return(
        <div className={classes.body}>
        <div >
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
        >
            { (st_load == 'complited') && <div className={classes.mb}>
                <p className={classes.info} > <img className={classes.logo} src={logoIcon}/></p>
                <p className={classes.info}><Typography>Ваше повідомлення подано {( "0" + d_reg.getDate() ).slice(-2)}.{( "0" + (d_reg.getMonth() + 1) ).slice(-2) }.{d_reg.getFullYear()}</Typography></p>
                <div className={classes.card}>
                <p className={classes.info}><Typography>Унікальний ідентифікатор повідомлення</Typography></p>
                <p className={classes.info}><Typography variant="h3">{uq_num}</Typography></p>
                <p className={classes.info}><div className={classes.linfo}>Для перевірки стану розгляду повідомлення Вам буде потрібний цей ідентифікатор</div></p>
                </div>
                <div className={classes.b_back}>
                    <Link href="/"><ColorButton>Повернутись на головну сторінку</ColorButton></Link>
                </div>
            </div> }
            {(st_load == 'in_progres') && <LoadWait/> }
            {(st_load == 'error') && <ErrorLoad />}
        </Box>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="15vh" 
        >
        <Grid container spacing={3}> 
        {(st_load != 'error') && <Grid items xs={12} align="center"><a href={file && ('/api/anketafile/' + file.id)} target="_blank" download className={classes.linkLoad}><div><SaveAltIcon/><span className={classes.label_load}>Зберегти повідомлення в PDF</span></div></a></Grid>}
        </Grid>
        </Box>
        </div>
        
        </div>
    )
}


