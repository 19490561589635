import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Header } from '../../components/header';

import { Footer1 } from '../../components/footer1';

import { MainBreadcrumbs } from '../../components/breadcrumb';

import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  body:{
      maxWidth: '1280px',
      minHeight: '600px',
      margin: 'auto',
      padding: '40px 85px 0px 85px'
  },
  form:{
      //padding: '50px'
  }
}));

const path_br = [
    { title: "Головна", link: "/" }
];

const cur_br = { title: "Хто такий викривач", link: "" }

export function VykryvachPage(){

        const classes = useStyles();

        return (
            <div >
                <Header short={true} />
                <div className={classes.body}>
                    <MainBreadcrumbs path={path_br} current={cur_br}/>
                    <LabelBlock/>
                    <div className={classes.form}><ContentVykryvach /></div>
                </div>
                <Footer1 />
            </div>
        )
}


function ContentVykryvach() {

    return (
        	<section className="content">
                <div className="container">
                    <div className="content-line">
                        <p className="p-vykryvach">Будь-яка людина може повідомити про корупцію, якщо переконана, що її інформація є достовірною. Але не завжди той, хто повідомив про корупцію, є викривачем.</p>
                        <p className="p-vykryvach"><b>Викривач</b> – особа, яка повідомила про корупцію, якщо інформація стала їй відома у зв’язку з її трудовою, професійною, господарською, громадською, науковою діяльністю, проходженням нею служби чи навчання.</p>
                        <span className="m-title">Умови, за сукупності яких особа є викривачем:</span>

                        
                            
                            <ul className="list-dot">
                                <li>це фізична особа (громадянин України, іноземець, особа без громадянства), яка має переконання, що інформація є достовірною;</li>
                                <li>подане повідомлення містить інформацію про факти корупційних або пов’язаних з корупцією правопорушень, інших порушень Закону України «Про запобігання корупції», тобто фактичні дані, які підтверджують можливе вчинення правопорушення та можуть бути перевірені;</li>
                                <li>інформація стала відома викривачу у зв’язку з його трудовою, професійною, господарською, громадською, науковою діяльністю, проходження служби чи навчання, участю у передбачених законодавством процедурах.</li>
                            </ul>

                        

                        <p className="p-vykryvach">Фактичні дані у повідомленні викривача мають складатися з інформації про конкретні факти порушення встановлених Законом України «Про запобігання корупції» вимог, заборон та обмежень, яке вчинене особою, зазначеною у ст. 3 цього Закону. Зокрема, це можуть бути відомості про обставини правопорушення, місце і час його вчинення, особу, яка вчинила правопорушення, тощо.</p>
                        <p className="p-vykryvach">Особа, яка повідомила про можливе правопорушення, <b>є заявником</b>, а не викривачем, якщо повідомлена нею інформація:
                        <ul className="list-num">
                            <li>не містить фактичних даних, які можуть бути перевірені;</li>
                            <li>стала їй відома не у зв’язку з певним видом діяльності, проходженням служби чи навчання або участю у передбачених законодавством процедурах а, наприклад, із загальнодоступних джерел (реєстрів, баз даних, соціальних мереж, засобів масової інформації).</li>
                        </ul>
                        </p>
                    </div>
                </div>
            </section>
    )
}

function LabelBlock(){
    return(
        <div className="anketa-header-titul">
            <span>Хто такий викривач</span>
        </div>
    )
}